import React, { useState } from "react";
import { Expense, ExpenseAddComponentType } from "../../models/expenses";
import ButtonSvg from "../button-svg/button-svg";
import Checkbox from "../checkbox/checkbox";
import ExpenseType from "./expense-type";
import { DebounceInput } from "react-debounce-input";
import AutoCompleteWrapper from "../auto-complete/auto-complete-wrapper";
import { Option } from "../../models/auto-complete";
import { getSavedExpenses } from "../../slices/sheet";
import { useSelector } from "react-redux";
import { expensesToOptions } from "../../utils/auto-complete";
import { GenericExpense } from "../../models/generic-expenses";

function ExpenseAdd(props: Props) {
  const [toWithdraw, setToWithdraw] = useState(false);
  const [amount, setAmount] = useState(0);
  const [expenseName, setExpenseName] = useState("");
  const [expenseType, setExpenseType] = useState("others" as Expense["type"]);

  const [errorMessage, setErrorMessage] = useState("");
  const [errorAmount, setErrorAmount] = useState(false);
  const [errorExpenseName, setErrorExpenseName] = useState(false);

  const savedExpenses = useSelector(getSavedExpenses);
  const autoCompleteOptions: Option[] = expensesToOptions(savedExpenses);

  const updateExpenseType = (type: Expense["type"]) => {
    setExpenseType(type);
  };

  const resetForm = (): void => {
    setToWithdraw(false);
    setAmount(0);
    setExpenseName("");
    setExpenseType("others");
  };

  const save = () => {
    if (expenseName === "") {
      setErrorMessage("Expense Name is required");
      setErrorExpenseName(true);
      return;
    }

    if (amount < 1) {
      setErrorMessage("Amount is required");
      setErrorAmount(true);
      return;
    }

    const expense: Expense = {
      _id: "",
      isPaid: false,
      amount,
      expenseName,
      toWithdraw,
      type: expenseType,
      sheet: "",
    };
    props.saveExpense?.(expense, resetForm);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div className="_row -message">
        <p className="-bold">Add new expense</p>
      </div>
      {errorMessage !== "" ? (
        <div className="_row -message -error">
          <p>{errorMessage}</p>
        </div>
      ) : (
        ""
      )}
      <div className="expenses-add _row">
        <div className="_cell -type">
          <ExpenseType
            type={expenseType}
            id="add-new-expense"
            showContextMenu={true}
            updateExpenseType={(type: Expense["type"]) =>
              updateExpenseType(type)
            }
          />{" "}
        </div>
        {props.type === ExpenseAddComponentType.Expense ? (
          <div className="_cell -checkbox">
            <Checkbox checked={false} name="isPaid" disabled={true} />
          </div>
        ) : (
          ""
        )}
        <div className="_cell -checkbox">
          <Checkbox
            checked={toWithdraw}
            onClick={() => setToWithdraw(!toWithdraw)}
            name="toWithdraw"
          />
        </div>
        <div className="_cell -text -amount">
          ₱{" "}
          <DebounceInput
            className={"_clearinput " + (errorAmount ? "-error" : "")}
            debounceTimeout={300}
            onChange={(event) => {
              const value = event.target.value || "0";
              setAmount(parseInt(value, 0));
              setErrorMessage("");
              setErrorAmount(false);
            }}
            type="number"
            value={amount}
          />
        </div>
        <div className="_cell -text -expense">
          {props.type === ExpenseAddComponentType.Expense ? (
            <AutoCompleteWrapper
              options={autoCompleteOptions}
              inputValue={expenseName}
              inputPlaceholder="Expense name..."
              inputClassName={
                "_clearinput " + (errorExpenseName ? "-error" : "")
              }
              onInputChange={(event) => {
                setExpenseName(event.target.value);
                setErrorMessage("");
                setErrorExpenseName(false);
              }}
              onOptionSelected={(option) => {
                setToWithdraw(option.toWithdraw);
                setAmount(option.amount);
                setExpenseName(option.expenseName);
                setExpenseType(option.type);
              }}
            />
          ) : (
            <DebounceInput
              className={"_clearinput " + (errorExpenseName ? "-error" : "")}
              debounceTimeout={300}
              placeholder="Expense name..."
              onChange={(event) => {
                setExpenseName(event.target.value);
                setErrorMessage("");
                setErrorExpenseName(false);
              }}
              value={expenseName}
            />
          )}
        </div>
        <div className="_cell -buttons">
          <ButtonSvg
            svg="save"
            tip="Save expense"
            label="Save Expense"
            onClick={() => {
              save();
            }}
          ></ButtonSvg>
        </div>
      </div>
    </form>
  );
}

interface Props {
  type: ExpenseAddComponentType;
  saveExpense: (expense: GenericExpense, callback: () => void) => void;
}

export default ExpenseAdd;
