import { ExpenseType } from "../models/expenses";

export const expenseTypes: ExpenseType[] = [
  {
    value: "savings",
    label: "Savings",
  },
  {
    value: "expenses",
    label: "Expenses",
  },
  {
    value: "subscriptions",
    label: "Subscriptions",
  },
  {
    value: "installment",
    label: "Installment",
  },
  {
    value: "others",
    label: "Others",
  },
];
