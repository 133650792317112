import { useCallback, useEffect } from "react";
import ExpenseBody from "../../../components-generic/expenses/expense-body";
import ExpensesWrapper from "../../../components-generic/expenses/expenses-wrapper";
import { BASE_URL } from "../../../data/constants";
import { Entity } from "../../../models/entities";
import { ExpenseComponentType, SavedExpense } from "../../../models/expenses";
import { GenericExpense } from "../../../models/generic-expenses";
import { Installment } from "../../../models/installments";
import { Template, TemplateExpense } from "../../../models/templates";
import { getQueryParams } from "../../../utils/requests";
import * as dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { isDateInBetween } from "../../../utils/dates";
import { getInstallments } from "../../../slices/sheet";
import { useSelector } from "react-redux";
import { handleEndpointError } from "../../../utils/handle-errors";

dayjs.extend(isBetween);

function AddSheetExpenses(props: Props) {
  const { setInstallmentExpenses, setTotal, setTemplateExpenses } = props;

  const installments = useSelector(getInstallments);

  const updateTotal = useCallback((): void => {
    let totalAmount = 0;
    props.templateExpenses.forEach((templateExpense: TemplateExpense) => {
      totalAmount += templateExpense.amount;
    });
    props.installmentExpenses.forEach((expense: GenericExpense) => {
      if (expense.include) {
        totalAmount += expense.amount;
      }
    });
    props.savedExpensesFiltered.forEach((expense: GenericExpense) => {
      if (expense.include) {
        totalAmount += expense.amount;
      }
    });
    setTotal(totalAmount);
  }, [
    props.templateExpenses,
    props.installmentExpenses,
    props.savedExpensesFiltered,
    setTotal,
  ]);

  useEffect(() => {
    const installmentExpenses: GenericExpense[] = installments
      .filter((installment: Installment) =>
        isDateInBetween(
          installment.startMonth,
          installment.startYear,
          installment.endMonth,
          installment.endYear,
          props.month,
          props.year
        )
      )
      .map((installment: Installment) => ({
        _id: installment._id,
        include: true,
        amount: installment.amount,
        expenseName: installment.expenseName,
        toWithdraw: false,
        type: "installment",
        installmentId: installment._id,
      }));

    setInstallmentExpenses(installmentExpenses);
  }, [
    props.entity,
    props.month,
    props.year,
    installments,
    setInstallmentExpenses,
  ]);

  useEffect(() => {
    const params = getQueryParams({ template: props.template._id });
    fetch(BASE_URL + "template-expense" + params)
      .then((res) => res.json())
      .then((res) => {
        setTemplateExpenses(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [props.template._id, setTemplateExpenses]);

  useEffect(() => {
    updateTotal();
  }, [
    props.templateExpenses,
    props.savedExpenses,
    props.savedExpensesFiltered,
    updateTotal,
  ]);

  const updateToIncludeInstallment = (id: GenericExpense["_id"]) => {
    const index = props.installmentExpenses.findIndex(
      (expense: GenericExpense) => expense._id === id
    );

    const newExpenses = [...props.installmentExpenses];
    newExpenses[index].include = !newExpenses[index].include;
    props.setInstallmentExpenses(newExpenses);
    updateTotal();
  };

  const updateToWithdrawInstallment = (id: GenericExpense["_id"]) => {
    const index = props.installmentExpenses.findIndex(
      (expense: GenericExpense) => expense._id === id
    );

    const newExpenses = [...props.installmentExpenses];
    newExpenses[index].toWithdraw = !newExpenses[index].toWithdraw;
    props.setInstallmentExpenses(newExpenses);
    updateTotal();
  };

  const updateToInclude = (id: GenericExpense["_id"]) => {
    const index = props.savedExpensesFiltered.findIndex(
      (expense: GenericExpense) => expense._id === id
    );

    const newExpenses = [...props.savedExpensesFiltered];
    newExpenses[index].include = !newExpenses[index].include;
    props.setSavedExpensesFiltered(newExpenses);
    updateTotal();
  };

  return (
    <div className="templates-expenses">
      <ExpensesWrapper
        type={ExpenseComponentType.AddSheetTemplates}
        total={props.total}
      >
        {props.templateExpenses.map((templateExpense: TemplateExpense) => {
          return (
            <ExpenseBody
              expense={templateExpense}
              key={templateExpense._id}
              type={ExpenseComponentType.AddSheetTemplates}
            />
          );
        })}

        {props.installmentExpenses.map((installmentExpense: GenericExpense) => {
          return (
            <ExpenseBody
              expense={installmentExpense}
              key={installmentExpense._id}
              type={ExpenseComponentType.AddSheetInstallment}
              month={props.month}
              year={props.year}
              updateToInclude={(id: GenericExpense["_id"]) => {
                updateToIncludeInstallment(id);
              }}
              updateToWithdraw={(id: GenericExpense["_id"]) => {
                updateToWithdrawInstallment(id);
              }}
            />
          );
        })}

        {props.savedExpensesFiltered.map((savedExpense: GenericExpense) => {
          return (
            <ExpenseBody
              expense={savedExpense}
              key={savedExpense._id}
              type={ExpenseComponentType.AddSheetSaved}
              updateToInclude={(id: GenericExpense["_id"]) => {
                updateToInclude(id);
              }}
            />
          );
        })}
      </ExpensesWrapper>
    </div>
  );
}

interface Props {
  template: Template;
  entity: Entity["key"];
  month: string;
  year: number;
  templateExpenses: TemplateExpense[];
  savedExpenses: SavedExpense[];
  savedExpensesFiltered: GenericExpense[];
  installmentExpenses: GenericExpense[];
  total: number;
  setTemplateExpenses: (expenses: TemplateExpense[]) => void;
  setSavedExpensesFiltered: (expenses: GenericExpense[]) => void;
  setInstallmentExpenses: (expenses: GenericExpense[]) => void;
  setTotal: (total: number) => void;
}

export default AddSheetExpenses;
