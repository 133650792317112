import Sheet from "../../components/sheet/sheet";
import { useParams } from "react-router-dom";

function SheetRoute() {
  const params: any = useParams();

  return (
    <Sheet
      entity={params.entity}
      year={parseInt(params.year, 0)}
      month={params.month}
      index={params.index}
    />
  );
}

export default SheetRoute;
