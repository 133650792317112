import React, { useContext } from "react";
import ThemeContext from "../../theme-context";
import "./header.scss";

function Header() {
  const theme = useContext(ThemeContext);

  return (
    <header className="header-component">
      <p className="title">Budget Sheets</p>
      {theme.demo ? <p className="demo">demo version</p> : ""}
      <p className="author">
        &nbsp;&nbsp;by{" "}
        <a
          href="https://gwenduling.com"
          target="_blank"
          rel="noreferrer"
          title="gwenduling.com"
        >
          gwenduling
        </a>
      </p>
    </header>
  );
}

export default Header;
