import React from "react";
import "./footer.scss";
import * as dayjs from "dayjs";

function Footer() {
  const date = dayjs.default().format("dddd, MMMM D, YYYY");
  return (
    <footer className="footer-component">
      <p>{date}</p>
    </footer>
  );
}

export default Footer;
