import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BLANK_MONTH_DATA } from "../../data/blanks";
import { Entity } from "../../models/entities";
import { Month, MonthData } from "../../models/months";
import { getNextMonth, getPreviousMonth } from "../../utils/dates";

function DateButtons(props: Props) {
  const [previousMonth, setPreviousMonth] = useState(
    BLANK_MONTH_DATA as MonthData
  );
  const [nextMonth, setNextMonth] = useState(BLANK_MONTH_DATA as MonthData);

  useEffect(() => {
    const prev = getPreviousMonth(props.month, props.year);
    const next = getNextMonth(props.month, props.year);

    setPreviousMonth(prev);
    setNextMonth(next);
  }, [props.month, props.year]);

  return (
    <div className="_button-group">
      <button className="_clearbutton">
        <Link
          to={`/sheet/${props.entity}/${previousMonth.month}/${previousMonth.year}`}
        >
          {previousMonth.label}
        </Link>
      </button>

      <button className="_clearbutton">
        <Link
          to={`/sheet/${props.entity}/${nextMonth.month}/${nextMonth.year}`}
        >
          {nextMonth.label}
        </Link>
      </button>
    </div>
  );
}

export default DateButtons;

interface Props {
  entity: Entity["key"];
  month: Month["key"];
  year: number;
}
