import "./page-nav.scss";
import Button from "../button/button";
import Legend from "../legend/legend";
import { Link } from "react-router-dom";

function PageNav() {
  return (
    <div className="page-nav">
      <Legend slim={true} />
      <div className="buttons-wrapper">
        <Button block={true}>
          <Link to={`/sheet/add`}>Add Sheet</Link>
        </Button>
        <Button block={true}>
          <Link to={`/expenses`}>Expenses</Link>
        </Button>
        <Button block={true}>
          <Link to={`/templates`}>Templates</Link>
        </Button>
        <Button block={true}>
          <Link to={`/installments`}>Installments</Link>
        </Button>
      </div>
    </div>
  );
}

export default PageNav;
