import "./sheets-heading.scss";
import { getEntityLabel } from "../../utils/sheets";
import { getSheetKey } from "../../slices/sheet";
import { useSelector } from "react-redux";
import Heading from "../heading/heading";
import { getMonthLabel } from "../../utils/dates";

function SheetsHeading(props: Props) {
  const sheetKey = useSelector(getSheetKey);

  return (
    <div className="sheets-heading">
      <Heading
        content={`${getEntityLabel(sheetKey.entity)} - ${getMonthLabel(
          sheetKey.month
        )} ${sheetKey.year}`}
      />

      <div className="sheet-title">{props.sheet ? props.sheet : ""}</div>
    </div>
  );
}

export default SheetsHeading;

interface Props {
  sheet?: string;
}
