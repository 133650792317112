import React from "react";
import "./legend.scss";

import SubHeading from "../sub-heading/sub-heading";

function Legend(props: Props) {
  let className = "legend-container ";

  if (props.slim) {
    className += "-slim";
  }

  return (
    <div className="legend-component">
      <SubHeading content="Legend" />
      <div className={className}>
        <div className="legend">
          <div className="color -savings"></div>
          <p className="name">Savings</p>
        </div>
        <div className="legend">
          <div className="color -expenses"></div>
          <p className="name">Expenses</p>
        </div>
        <div className="legend">
          <div className="color -installments"></div>
          <p className="name">Installments</p>
        </div>
        <div className="legend">
          <div className="color -subscriptions"></div>
          <p className="name">Subscriptions</p>
        </div>
        <div className="legend">
          <div className="color -others"></div>
          <p className="name">Others</p>
        </div>
      </div>
    </div>
  );
}

export default Legend;

interface Props {
  slim?: boolean;
}
