import React from "react";
import saveButton from "../../assets/svg/save.svg";
import undoButton from "../../assets/svg/undo.svg";
import editButton from "../../assets/svg/edit.svg";
import deleteButton from "../../assets/svg/delete.svg";
import addButton from "../../assets/svg/add.svg";
import inactiveButton from "../../assets/svg/inactive.svg";
import activeButton from "../../assets/svg/active.svg";
import installmentButton from "../../assets/svg/installment.svg";

import Tooltip from "rc-tooltip";

function ButtonSvg(props: Props) {
  const getSVG = (): string => {
    switch (props.svg) {
      case "save":
        return saveButton;

      case "undo":
        return undoButton;

      case "edit":
        return editButton;

      case "delete":
        return deleteButton;

      case "add":
        return addButton;

      case "inactive":
        return inactiveButton;

      case "active":
        return activeButton;

      case "installment":
        return installmentButton;
    }
  };

  const svg = getSVG();
  let buttonClass = "_clearbutton";

  if (props.disabled) {
    buttonClass += " -disabled";
  }

  return (
    <div>
      <Tooltip
        placement="right"
        trigger={["hover"]}
        overlay={<span>{props.tip}</span>}
        mouseEnterDelay={0.3}
      >
        <button className={buttonClass} onClick={() => props.onClick?.()}>
          <img src={svg} alt={props.label} />
        </button>
      </Tooltip>
    </div>
  );
}

interface Props {
  disabled?: boolean;
  onClick?: () => void;
  svg:
    | "save"
    | "undo"
    | "edit"
    | "delete"
    | "add"
    | "inactive"
    | "active"
    | "installment";
  tip: string;
  label: string;
}

export default ButtonSvg;
