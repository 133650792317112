import { createSlice } from "@reduxjs/toolkit";
import {
  BLANK_EARNING,
  BLANK_EXPENSE,
  BLANK_INSTALLMENT,
  BLANK_SAVED_EXPENSE,
} from "../data/blanks";
import { entities } from "../data/entities";
import { Earning } from "../models/earnings";
import { Entity } from "../models/entities";
import { Expense, SavedExpense } from "../models/expenses";
import { Installment } from "../models/installments";
import { SheetKey } from "../models/sheets";

export const sheetSlice = createSlice({
  name: "sheets",
  initialState: {
    keys: {
      year: 2022,
      entity: "kwe",
      month: "mar",
    },
    entity: entities[0],
    expenses: [BLANK_EXPENSE],
    earnings: [BLANK_EARNING],
    savedExpenses: [BLANK_SAVED_EXPENSE],
    installments: [BLANK_INSTALLMENT],
  },
  reducers: {
    setSheetKey: (state: SheetState, action) => {
      const { year, month, entity } = action.payload as SheetKey;
      state.keys.year = year;
      state.keys.month = month;
      state.keys.entity = entity;
    },
    setEntity: (state: SheetState, action) => {
      localStorage.setItem("entity", action.payload.key);
      state.entity = action.payload;
    },
    setExpenses: (state: SheetState, action) => {
      state.expenses = action.payload;
    },
    setEarnings: (state: SheetState, action) => {
      state.earnings = action.payload;
    },
    setSavedExpenses: (state: SheetState, action) => {
      state.savedExpenses = action.payload;
    },

    setInstallments: (state: SheetState, action) => {
      state.installments = action.payload;
    },
  },
});

export const {
  setSheetKey,
  setEntity,
  setExpenses,
  setEarnings,
  setSavedExpenses,
  setInstallments,
} = sheetSlice.actions;

export const getSheetKey = (state: SheetState) => state.keys;
export const getEntity = (state: SheetState) => state.entity;
export const getExpenses = (state: SheetState) => state.expenses;
export const getEarnings = (state: SheetState) => state.earnings;
export const getSavedExpenses = (state: SheetState) => state.savedExpenses;
export const getInstallments = (state: SheetState) => state.installments;

export default sheetSlice.reducer;

interface SheetState {
  entity: Entity;
  keys: SheetKey;
  expenses: Expense[];
  earnings: Earning[];
  savedExpenses: SavedExpense[];
  installments: Installment[];
}
