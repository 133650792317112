import React, { useContext } from "react";
import ThemeContext from "../../theme-context";
import "./button.scss";

function Button(props: Props) {
  const context = useContext(ThemeContext);

  let buttonClass = `button -${context.theme}`;
  if (props.block) {
    buttonClass += " -block";
  }

  if (props.active) {
    buttonClass += " -active";
  }

  if (props.disabled) {
    buttonClass += " -disabled";
  }

  if (props.slim) {
    buttonClass += " -slim";
  }

  if (!props.children) {
    buttonClass += " -padding";
  }

  return (
    <button
      className={buttonClass}
      onClick={() => props.onClick?.()}
      type={props.submit ? "submit" : "button"}
      disabled={props.disabled}
    >
      {props.children ? props.children : props.content}
    </button>
  );
}

export default Button;

interface Props {
  content?: string;
  block?: boolean;
  active?: boolean;
  submit?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  slim?: boolean;
}
