import { useCallback, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { months } from "../../../data/months";
import { Month } from "../../../models/months";
import * as dayjs from "dayjs";
import { getMonthFromIndex, getMonthIndex } from "../../../utils/dates";
import ButtonSvg from "../../../components-generic/button-svg/button-svg";
import { Installment } from "../../../models/installments";
import { Entity } from "../../../models/entities";

function AddInstallment(props: Props) {
  const selectStartYear = 2020;
  const currentYear = dayjs.default().get("year");
  const diff = currentYear - selectStartYear;

  const [amount, setAmount] = useState(0);
  const [expenseName, setExpenseName] = useState("");
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [startYear, setStartYear] = useState(0);
  const [endYear, setEndYear] = useState(0);
  const [total, setTotal] = useState(0);
  const [dateDiff, setDateDiff] = useState(0);

  const [errorAmount, setErrorAmount] = useState(false);
  const [errorExpenseName, setErrorExpenseName] = useState(false);
  const [errorStartMonth, setErrorStartMonth] = useState(false);
  const [errorEndMonth, setErrorEndMonth] = useState(false);
  const [errorStartYear, setErrorStartYear] = useState(false);
  const [errorEndYear, setErrorEndYear] = useState(false);
  const [errorTotal, setErrorTotal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isValidDates = useCallback((): boolean => {
    const startMonthIndex = getMonthIndex(startMonth);
    const endMonthIndex = getMonthIndex(endMonth);

    const startDate = dayjs.default(`${startYear}-${startMonthIndex}-01`);
    const endDate = dayjs.default(`${endYear}-${endMonthIndex}-01`);
    const diff = endDate.diff(startDate, "month");
    setDateDiff(diff);

    if (diff < 1) {
      setErrorMessage("End date should be after start date");
      setErrorStartMonth(true);
      setErrorStartYear(true);
      setErrorEndMonth(true);
      setErrorEndYear(true);
      return false;
    }

    return true;
  }, [startMonth, startYear, endMonth, endYear]);

  useEffect(() => {
    resetDates();
  }, []);

  useEffect(() => {
    isValidDates();
  }, [startMonth, startYear, endMonth, endYear, isValidDates]);

  const save = () => {
    if (expenseName === "") {
      setErrorMessage("Expense Name is required");
      setErrorExpenseName(true);
      return;
    }

    if (amount < 1) {
      setErrorMessage("Monthly amount is required");
      setErrorAmount(true);
      return;
    }

    if (total < 1) {
      setErrorMessage("Total is required");
      setErrorTotal(true);
      return;
    }

    if (total <= amount) {
      setErrorMessage("Total should be more than the monthly amount");
      setErrorTotal(true);
      setErrorAmount(true);
      return;
    }

    if (!isValidDates()) {
      return;
    }

    const installment: Installment = {
      _id: "",
      entity: props.entity,
      amount,
      expenseName,
      total,
      startMonth,
      startYear,
      endMonth,
      endYear,
    };
    props.saveInstallment(installment, resetForm);
  };

  const resetForm = (): void => {
    setAmount(0);
    setTotal(0);
    setExpenseName("");
    resetDates();
  };

  const resetDates = (): void => {
    const monthIndex = dayjs.default().get("month");
    setStartMonth(getMonthFromIndex(monthIndex));
    setStartYear(dayjs.default().get("year"));
    setEndMonth(getMonthFromIndex(monthIndex));
    setEndYear(dayjs.default().get("year"));
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      {errorMessage !== "" ? (
        <div className="_row -message -error">
          <p>{errorMessage}</p>
        </div>
      ) : (
        ""
      )}
      <div className="add-instalment _row">
        <div className="_cell -amount">
          <span>
            ₱{" "}
            <DebounceInput
              className={"_clearinput " + (errorTotal ? "-error" : "")}
              debounceTimeout={300}
              onChange={(event) => {
                const value = event.target.value || "0";
                setTotal(parseInt(value, 0));
                setErrorMessage("");
                setErrorTotal(false);
                setErrorAmount(false);
              }}
              type="number"
              value={total}
            />
          </span>
        </div>
        <div className="_cell -amount">
          <span>
            ₱{" "}
            <DebounceInput
              className={"_clearinput " + (errorAmount ? "-error" : "")}
              debounceTimeout={300}
              onChange={(event) => {
                const value = event.target.value || "0";
                setAmount(parseInt(value, 0));
                setErrorMessage("");
                setErrorAmount(false);
                setErrorTotal(false);
              }}
              type="number"
              value={amount}
            />
          </span>
        </div>
        <div className="_cell -expense">
          <DebounceInput
            className={"_clearinput " + (errorExpenseName ? "-error" : "")}
            debounceTimeout={300}
            onChange={(event) => {
              setExpenseName(event.target.value);
              setErrorMessage("");
              setErrorExpenseName(false);
            }}
            placeholder="Expense Name..."
            value={expenseName}
          />
        </div>
        <div className="_cell -date-inputs">
          <select
            className={
              "_input _clearselect " + (errorStartMonth ? "-error" : "")
            }
            onChange={(event) => {
              setStartMonth(event.target.value);
              setErrorMessage("");
              setErrorStartMonth(false);
              setErrorStartYear(false);
              setErrorEndMonth(false);
              setErrorEndYear(false);
            }}
            value={startMonth}
          >
            {months.map((mo: Month) => (
              <option key={mo.key} value={mo.key}>
                {" "}
                {mo.label}{" "}
              </option>
            ))}
          </select>

          <select
            className={
              "_input _clearselect " + (errorStartYear ? "-error" : "")
            }
            onChange={(val) => {
              setStartYear(parseInt(val.target.value, 0));
              setErrorMessage("");
              setErrorStartMonth(false);
              setErrorStartYear(false);
              setErrorEndMonth(false);
              setErrorEndYear(false);
            }}
            value={startYear}
          >
            {[...Array(diff + 30)].map((current, i) => (
              <option key={i}> {i + selectStartYear} </option>
            ))}
          </select>

          <select
            className={"_input _clearselect " + (errorEndMonth ? "-error" : "")}
            onChange={(event) => {
              setEndMonth(event.target.value);
              setErrorMessage("");
              setErrorStartMonth(false);
              setErrorStartYear(false);
              setErrorEndMonth(false);
              setErrorEndYear(false);
            }}
            value={endMonth}
          >
            {months.map((mo: Month) => (
              <option key={mo.key} value={mo.key}>
                {" "}
                {mo.label}{" "}
              </option>
            ))}
          </select>

          <select
            className={"_input _clearselect " + (errorEndYear ? "-error" : "")}
            onChange={(val) => {
              setEndYear(parseInt(val.target.value, 0));
              setErrorMessage("");
              setErrorStartMonth(false);
              setErrorStartYear(false);
              setErrorEndMonth(false);
              setErrorEndYear(false);
            }}
            value={endYear}
          >
            {[...Array(diff + 30)].map((current, i) => (
              <option key={i}> {i + selectStartYear} </option>
            ))}
          </select>

          <p className="total-months">({dateDiff} Months)</p>
        </div>

        <div className="_cell -buttons">
          <ButtonSvg
            svg="save"
            tip="Save installment"
            label="Save Installment"
            onClick={() => {
              save();
            }}
          ></ButtonSvg>
        </div>
      </div>
    </form>
  );
}

export default AddInstallment;

interface Props {
  entity: Entity["key"];
  saveInstallment: (installment: Installment, callback: () => void) => void;
}
