import Sheets from "../../components/sheets/sheets";
import { useParams } from "react-router-dom";

function SheetsRoute() {
  const params: any = useParams();
  return (
    <Sheets
      entity={params.entity}
      year={parseInt(params.year, 0)}
      month={params.month}
    />
  );
}

export default SheetsRoute;
