import "./auto-complete.scss";
import { Option } from "../../models/auto-complete";

function AutoComplete(props: Props) {
  return (
    <div className="options-list">
      {props.options.length === 0 ? (
        <div className="empty">0 options match</div>
      ) : (
        ""
      )}

      {props.options.map((option: Option, index: number) => {
        return (
          <button
            className={
              "_clearbutton option-item" +
              (props.activeOption === index ? " -active" : "")
            }
            key={index}
            onClick={(event) => {
              event.preventDefault();
              props.onClick(option);
            }}
          >
            <p className="name">{option.expenseName}</p>
            <p className="type">{option.type}</p>
          </button>
        );
      })}
    </div>
  );
}

export default AutoComplete;

interface Props {
  options: Option[];
  activeOption: number;
  onClick: (option: Option) => void;
}
