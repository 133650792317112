import React from "react";
import { ExpenseType, Expense } from "../../models/expenses";
import { getExpenseTypeLabel } from "../../utils/expense";
import { Menu, Item, useContextMenu, ItemParams } from "react-contexify";
import Tooltip from "rc-tooltip";
import { expenseTypes } from "../../data/expense-types";

function ExpenseTypeComponent(props: Props) {
  const typeLabel = getExpenseTypeLabel(props.type);

  const { show } = useContextMenu({
    id: props.id,
  });

  function handleContextMenu(
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ): void {
    event.preventDefault();
    if (props.showContextMenu) {
      show(event, {});
    }
  }

  const handleItemClick = (args: ItemParams<any, any>) => {
    if (args.data !== props.type) {
      props.updateExpenseType(args.data);
    }
  };

  return (
    <div className="expenses-type">
      <Tooltip
        placement="right"
        trigger={["hover"]}
        overlay={<span>{typeLabel}</span>}
        mouseEnterDelay={0.3}
      >
        <div
          className={"type -" + props.type}
          onClick={handleContextMenu}
        ></div>
      </Tooltip>

      {props.showContextMenu ? (
        <Menu id={props.id}>
          {expenseTypes
            .filter((type: ExpenseType) => type.value !== "installment")
            .map((type: ExpenseType) => {
              return (
                <Item
                  key={type.value}
                  onClick={handleItemClick}
                  data={type.value}
                >
                  <div className={"color -" + type.value}></div>
                  <p className="text">{type.label}</p>
                </Item>
              );
            })}
        </Menu>
      ) : (
        ""
      )}
    </div>
  );
}

interface Props {
  type: Expense["type"];
  id: Expense["_id"];
  showContextMenu: boolean;
  updateExpenseType: (type: Expense["type"]) => void;
}

export default ExpenseTypeComponent;
