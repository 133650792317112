import { useState } from "react";
import Button from "../../../components-generic/button/button";
import { DebounceInput } from "react-debounce-input";
import { Template } from "../../../models/templates";
import SubHeading from "../../../components-generic/sub-heading/sub-heading";

function AddTemplate(props: Props) {
  const [label, setLabel] = useState("");

  const [errorLabel, setErrorLabel] = useState(false);

  const addTemplate = (): void => {
    if (label === "") {
      setErrorLabel(true);
      return;
    }

    props.addTemplate(label, resetForm);
  };

  const resetForm = (): void => {
    setErrorLabel(false);
    setLabel("");
  };

  return (
    <div className="add-template _margin-bottom">
      <SubHeading content="Add Template" />
      <form
        className="_margin-bottom"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {errorLabel ? <p className="_error-message">Label is required</p> : ""}
        <DebounceInput
          className={"_input " + (errorLabel ? "-error" : "")}
          debounceTimeout={300}
          onChange={(event) => {
            setLabel(event.target.value);
            setErrorLabel(false);
          }}
          value={label}
          placeholder="Template label..."
        />
      </form>
      <Button
        content="Add Template"
        block={true}
        onClick={() => addTemplate()}
      />
    </div>
  );
}

export default AddTemplate;

interface Props {
  addTemplate: (label: Template["label"], callback: () => void) => void;
}
