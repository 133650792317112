import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import EntityFilter from "../../components-generic/entity-filter/entity-filter";
import Heading from "../../components-generic/heading/heading";
import Legend from "../../components-generic/legend/legend";
import { BLANK_TEMPLATE } from "../../data/blanks";
import { BASE_URL } from "../../data/constants";
import { Entity } from "../../models/entities";
import { Expense, SavedExpense } from "../../models/expenses";
import { GenericExpense } from "../../models/generic-expenses";
import { Sheet } from "../../models/sheets";
import { Template, TemplateExpense } from "../../models/templates";
import { getQueryParams, getRequestOptions } from "../../utils/requests";
import TemplatesList from "../templates/templates-list/templates-list";
import AddSheetExpenses from "./add-sheet-expenses/add-sheet-expenses";
import AddSheetForm from "./add-sheet-form/add-sheet-form";
import "./add-sheet.scss";
import { useNavigate } from "react-router-dom";
import {
  handleDemoError,
  handleEndpointError,
} from "../../utils/handle-errors";
import { useDispatch, useSelector } from "react-redux";
import { getEntity, setEntity, setInstallments } from "../../slices/sheet";
import ThemeContext from "../../theme-context";
import * as dayjs from "dayjs";
import { getMonthFromIndex } from "../../utils/dates";

function AddSheet() {
  const dispatch = useDispatch();
  const entity = useSelector(getEntity);
  const theme = useContext(ThemeContext);

  const [templates, setTemplates] = useState([] as Template[]);
  const [template, setTemplate] = useState(BLANK_TEMPLATE as Template);

  const [savedExpenses, setSavedExpenses] = useState([] as SavedExpense[]);
  const [templateExpenses, setTemplateExpenses] = useState(
    [] as TemplateExpense[]
  );
  const [total, setTotal] = useState(0);
  const [savedExpensesFiltered, setSavedExpensesFiltered] = useState(
    [] as GenericExpense[]
  );
  const [installmentExpenses, setInstallmentExpenses] = useState(
    [] as GenericExpense[]
  );

  const [month, setMonth] = useState("");
  const [year, setYear] = useState(0);

  let navigate = useNavigate();

  useEffect(() => {
    const monthIndex = dayjs.default().get("month");
    setMonth(getMonthFromIndex(monthIndex));
    setYear(dayjs.default().get("year"));
  }, []);

  useEffect(() => {
    const params = getQueryParams({ entity: entity.key });
    fetch(BASE_URL + "template" + params)
      .then((res) => res.json())
      .then((res) => {
        setTemplates(res);

        if (res[0]) {
          setTemplate(res[0]);
        }
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity.key]);

  useEffect(() => {
    const params = getQueryParams({ entity: entity.key });

    fetch(BASE_URL + "installment" + params)
      .then((res) => res.json())
      .then((res) => {
        dispatch(setInstallments(res));
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity.key, dispatch]);

  useEffect(() => {
    const params = getQueryParams({ entity: entity.key });
    fetch(BASE_URL + "saved-expense" + params)
      .then((res) => res.json())
      .then((res) => {
        setSavedExpenses(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity.key]);

  useEffect(() => {
    const newSavedExpensesFiltered = savedExpenses
      .filter(
        (savedExpense: SavedExpense) =>
          !templateExpenses.some(
            (templateExpense: TemplateExpense) =>
              savedExpense.expenseName === templateExpense.expenseName &&
              savedExpense.amount === templateExpense.amount
          )
      )
      .map((expense: GenericExpense) => ({
        ...expense,
        include: expense.include || false,
      }));

    setSavedExpensesFiltered(newSavedExpensesFiltered);
  }, [templateExpenses, savedExpenses]);

  const onEntityChange = (entity: Entity): void => {
    dispatch(setEntity(entity));
  };

  const selectTemplate = (id: Template["_id"]): void => {
    const index = templates.findIndex((template) => template._id === id);
    const template = templates[index];

    setTemplate(template);
  };

  const addSheet = (
    label: Sheet["label"],
    year: Sheet["year"],
    month: Sheet["month"],
    callback: () => void
  ): void => {
    const sheet: Sheet = {
      _id: "",
      entity: entity.key,
      label,
      month,
      year,
    };

    if (theme.demo) {
      handleDemoError();
      return;
    }

    const params = getRequestOptions("POST", sheet);
    fetch(BASE_URL + "sheet", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Successfully added sheet ${sheet.label}`, {
          type: "success",
        });

        callback();
        addSheetExpenses(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const addSheetExpenses = (sheet: Sheet): void => {
    const templates: Omit<Expense, "_id">[] = templateExpenses.map(
      (expense: TemplateExpense): Omit<Expense, "_id"> => ({
        sheet: sheet._id,
        expenseName: expense.expenseName,
        amount: expense.amount,
        type: expense.type,
        toWithdraw: expense.toWithdraw,
        isPaid: false,
        installmentId: expense.installmentId || "",
      })
    );

    const installment: Omit<Expense, "_id">[] = installmentExpenses
      .filter((expense: GenericExpense) => expense.include)
      .map(
        (expense: GenericExpense): Omit<Expense, "_id"> => ({
          sheet: sheet._id,
          expenseName: expense.expenseName,
          amount: expense.amount,
          type: expense.type,
          toWithdraw: expense.toWithdraw,
          isPaid: false,
          installmentId: expense.installmentId,
        })
      );

    const savedExpenses: Omit<Expense, "_id">[] = savedExpensesFiltered
      .filter((expense: GenericExpense) => expense.include)
      .map(
        (expense: GenericExpense): Omit<Expense, "_id"> => ({
          sheet: sheet._id,
          expenseName: expense.expenseName,
          amount: expense.amount,
          type: expense.type,
          toWithdraw: expense.toWithdraw,
          isPaid: false,
          installmentId: expense.installmentId || "",
        })
      );

    const params = getRequestOptions("POST", [
      ...templates,
      ...installment,
      ...savedExpenses,
    ]);
    fetch(BASE_URL + "expenses", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Expenses for sheet ${sheet.label} added successfully!`, {
          type: "success",
        });

        getEntitySheets(sheet);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const getEntitySheets = (sheet: Sheet) => {
    const params = getQueryParams({
      entity: entity.key,
      month: sheet.month,
      year: sheet.year,
    });
    fetch(BASE_URL + "sheet" + params)
      .then((res) => res.json())
      .then((res) => {
        const index = res.length - 1;
        navigate(
          `../sheet/${sheet.entity}/${sheet.month}/${sheet.year}/${index}`,
          { replace: true }
        );
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const setTemplateExpensesCallback = useCallback(
    (expenses: TemplateExpense[]) => {
      setTemplateExpenses(expenses);
    },
    []
  );

  const setSavedExpensesFilteredCallback = useCallback(
    (expenses: GenericExpense[]) => {
      setSavedExpensesFiltered(expenses);
    },
    []
  );

  const setExpensesInstallmentCallback = useCallback(
    (expenses: GenericExpense[]) => {
      setInstallmentExpenses(expenses);
    },
    []
  );

  const setTotalCallback = useCallback((total: number) => {
    setTotal(total);
  }, []);

  return (
    <div className="add-sheet-component">
      <Heading content="Add Sheet" />
      <div className="_page-flex _page-height">
        <div className="_fixed _margin-right">
          <EntityFilter
            entityKey={entity.key}
            onChange={(entity: Entity) => onEntityChange(entity)}
          />

          <TemplatesList
            templates={templates}
            template={template}
            selectTemplate={(id: Template["_id"]) => selectTemplate(id)}
          />

          <AddSheetForm
            month={month}
            year={year}
            setMonth={(month: Sheet["month"]) => setMonth(month)}
            setYear={(year: Sheet["year"]) => setYear(year)}
            addSheet={(
              label: Sheet["label"],
              year: Sheet["year"],
              month: Sheet["month"],
              callback: () => void
            ) => addSheet(label, year, month, callback)}
          />
        </div>
        <div className="_grow">
          {" "}
          <Legend />
          <AddSheetExpenses
            entity={entity.key}
            month={month}
            year={year}
            template={template}
            templateExpenses={templateExpenses}
            savedExpenses={savedExpenses}
            savedExpensesFiltered={savedExpensesFiltered}
            installmentExpenses={installmentExpenses}
            total={total}
            setTemplateExpenses={setTemplateExpensesCallback}
            setSavedExpensesFiltered={setSavedExpensesFilteredCallback}
            setInstallmentExpenses={setExpensesInstallmentCallback}
            setTotal={setTotalCallback}
          />
        </div>
      </div>
    </div>
  );
}

export default AddSheet;
