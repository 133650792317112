import React, { useEffect, useState } from "react";
import "./auto-complete.scss";
import { Option } from "../../models/auto-complete";
import { DebounceInput } from "react-debounce-input";
import AutoComplete from "./auto-complete";
import { Expense, SavedExpense } from "../../models/expenses";

function AutoCompleteWrapper(props: Props) {
  const [filteredOptions, setFilteredOptions] = useState(
    props.options as Option[]
  );
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [activeOption, setActiveOption] = useState(-1);
  const [keyDirection, setKeyDirection] = useState("" as "up" | "down" | "");
  const [scrollType, setScrollType] = useState(
    "" as "quick" | "element-top" | ""
  );

  useEffect(() => {
    if (keyDirection === "" || scrollType === "") return;
    showInView(keyDirection, scrollType);
  }, [activeOption, keyDirection, scrollType]);

  const updateFilter = (value: string): void => {
    if (value === "") {
      setFilteredOptions(props.options);
      return;
    }

    const filter = props.options.filter((option: Option) =>
      option.expenseName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filter);
  };

  const onKeydown = (event: React.KeyboardEvent): void => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        onArrowDown();
        break;

      case "ArrowUp":
        event.preventDefault();
        onArrowUp();
        break;

      case "Enter":
        event.preventDefault();

        /* istanbul ignore if */
        if (activeOption === -1) return;
        props.onOptionSelected(props.options[activeOption]);
        closeAutoComplete();
        break;
    }
  };

  const onArrowDown = (): void => {
    if (activeOption === -1) {
      setActiveOption(0);
      setKeyDirection("down");
      setScrollType("element-top");
    } else {
      const option = filteredOptions[activeOption];

      /* istanbul ignore else */
      if (option) {
        let newIndex = activeOption + 1;
        let scrollType: "quick" | "element-top" = "quick";
        if (activeOption === filteredOptions.length - 1) {
          newIndex = 0;
          scrollType = "element-top";
        }

        setActiveOption(newIndex);
        setKeyDirection("down");
        setScrollType(scrollType);
      }
    }
  };

  const onArrowUp = (): void => {
    if (activeOption === -1) {
      setActiveOption(filteredOptions.length - 1);
      setKeyDirection("up");
      setScrollType("element-top");
    } else {
      const option = filteredOptions[activeOption];

      /* istanbul ignore else */
      if (option) {
        let newIndex = activeOption - 1;
        let scrollType: "quick" | "element-top" = "quick";
        if (activeOption === 0) {
          newIndex = filteredOptions.length - 1;
          scrollType = "element-top";
        }

        setActiveOption(newIndex);
        setKeyDirection("up");
        setScrollType(scrollType);
      }
    }
  };

  const showInView = (
    key: "down" | "up",
    scrollType: "quick" | "element-top"
  ): void => {
    const element: HTMLElement | null = document.querySelector(
      ".auto-complete .option-item.-active"
    );
    const container: HTMLElement | null = document.querySelector(
      ".auto-complete .options-list"
    );

    /* istanbul ignore else */
    if (element && container) {
      const containerCoord = container.getBoundingClientRect();
      const elementCoord = element.getBoundingClientRect();

      if (
        containerCoord.top > elementCoord.top ||
        containerCoord.bottom < elementCoord.bottom
      ) {
        const offset: number = key === "down" ? 30 : -30;

        container.scrollTop =
          scrollType === "quick"
            ? container.scrollTop + offset
            : elementCoord.top - containerCoord.top;
      }
    }
  };

  const closeAutoComplete = () => {
    setTimeout(() => {
      setShowAutoComplete(false);
    }, 500);
  };

  return (
    <div className="auto-complete-wrapper">
      {showAutoComplete ? (
        <div className="auto-complete">
          <AutoComplete
            activeOption={activeOption}
            options={filteredOptions}
            onClick={(option: Option) => props.onOptionSelected(option)}
          />
        </div>
      ) : (
        ""
      )}

      <DebounceInput
        className={props.inputClassName}
        type="text"
        placeholder={props.inputPlaceholder}
        autoComplete="off"
        debounceTimeout={250}
        onChange={(event) => {
          props.onInputChange(event);
          updateFilter(event.target.value);
        }}
        onFocus={() => {
          setActiveOption(-1);
          setShowAutoComplete(true);
          setFilteredOptions(props.options);
        }}
        onBlur={() => {
          closeAutoComplete();
        }}
        onKeyDown={(event: React.KeyboardEvent) => {
          onKeydown(event);
        }}
        value={props.inputValue}
      />
    </div>
  );
}

export default AutoCompleteWrapper;

interface Props {
  options: Option[];
  inputValue: Expense["expenseName"] | SavedExpense["expenseName"];
  inputPlaceholder: string;
  inputClassName: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOptionSelected: (option: Option) => void;
}
