import { Entity } from "./entities";
import { Sheet } from "./sheets";

export interface Expense {
  _id: string;
  sheet: Sheet["_id"];
  isPaid: boolean;
  toWithdraw: boolean;
  amount: number;
  expenseName: string;
  type: "savings" | "expenses" | "subscriptions" | "installment" | "others";
  installmentId?: string;
}

export interface ExpenseType {
  value: string;
  label: string;
}

export interface SavedExpense {
  _id: string;
  entity: Entity["key"];
  amount: number;
  expenseName: string;
  type: Expense["type"];
  toWithdraw: boolean;
}

export enum ExpenseComponentType {
  Sheet = "sheet",
  Sheets = "sheets",
  AddSheetTemplates = "add-templates",
  AddSheetSaved = "add-saved",
  AddSheetInstallment = "add-installment",
  Templates = "templates",
  TemplatesSaved = "templates-saved",
  SavedExpenses = "saved",
}

export enum ExpenseAddComponentType {
  Expense = "expense",
  SavedExpenses = "savedExpense",
  Templates = "templates",
}
