import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonSvg from "../../../components-generic/button-svg/button-svg";
import Button from "../../../components-generic/button/button";
import SubHeading from "../../../components-generic/sub-heading/sub-heading";
import { ExpenseComponentType } from "../../../models/expenses";
import { Sheet } from "../../../models/sheets";
import { getSheetKey } from "../../../slices/sheet";
import Expenses from "../../expenses/expenses";
import "./sheets-list.scss";

function SheetsList(props: Props) {
  const sheetKey = useSelector(getSheetKey);

  const { year, entity, month } = sheetKey;

  return (
    <div className="sheets-list">
      {props.sheets.map((sheet: Sheet, index: number) => {
        return (
          <div key={sheet._id} className="sheet">
            <div className="_flex">
              <SubHeading content={sheet.label} />
              <div className="_left-auto sheets-buttons">
                <Button slim={true}>
                  <Link to={`/sheet/${entity}/${month}/${year}/${index}`}>
                    Open Sheet
                  </Link>
                </Button>
                &nbsp;
                <ButtonSvg
                  svg="delete"
                  tip="Delete Sheet"
                  label="Delete Sheet"
                  onClick={() => props.deleteSheet(sheet._id)}
                />
              </div>
            </div>
            <Expenses sheet={sheet} type={ExpenseComponentType.Sheets} />
          </div>
        );
      })}
    </div>
  );
}

interface Props {
  sheets: Sheet[];
  deleteSheet: (id: Sheet["_id"]) => void;
}

export default SheetsList;
