import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ExpenseAdd from "../../components-generic/expenses/expense-add";
import ExpenseBody from "../../components-generic/expenses/expense-body";
import ExpensesWrapper from "../../components-generic/expenses/expenses-wrapper";
import { BASE_URL } from "../../data/constants";
import {
  Expense,
  ExpenseAddComponentType,
  ExpenseComponentType,
} from "../../models/expenses";
import { GenericExpense } from "../../models/generic-expenses";
import { Sheet } from "../../models/sheets";
import { setExpenses } from "../../slices/sheet";
import ThemeContext from "../../theme-context";
import {
  handleDemoError,
  handleEndpointError,
} from "../../utils/handle-errors";
import { getQueryParams, getRequestOptions } from "../../utils/requests";

function Expenses(props: Props) {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const [expenses, setExpense] = useState([] as Expense[]);
  useEffect(() => {
    const params = getQueryParams({ sheet: props.sheet._id });
    fetch(BASE_URL + "expense" + params)
      .then((res) => res.json())
      .then((res) => {
        dispatch(setExpenses(props.type === "sheets" ? [] : res));
        setExpense(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [dispatch, props.type, props.sheet._id]);

  const updateIsPaid = (id: Expense["_id"]): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const index = expenses.findIndex((expense: Expense) => expense._id === id);
    const expense = expenses[index];

    const isPaid = !expense.isPaid;
    const newExpense = { ...expense, isPaid };

    const params = getRequestOptions("POST", { id, isPaid });
    fetch(BASE_URL + "update-expense-is-paid", params)
      .then((res) => res.json())
      .then((res) => {
        const message = isPaid
          ? `${expense.expenseName} set to paid!`
          : `unchecked ${expense.expenseName} successfully!`;
        toast(message, { type: "success" });
        updateExpenseState(index, newExpense);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const updateToWithdraw = (id: Expense["_id"]): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const index = expenses.findIndex((expense: Expense) => expense._id === id);
    const expense = expenses[index];

    const toWithdraw = !expense.toWithdraw;
    const newExpense = { ...expense, toWithdraw };

    const params = getRequestOptions("POST", { id, toWithdraw });
    fetch(BASE_URL + "update-expense-to-withdraw", params)
      .then((res) => res.json())
      .then((res) => {
        const message = toWithdraw
          ? `${expense.expenseName} set to withdraw!`
          : `${expense.expenseName} set to not withdraw!`;
        toast(message, { type: "success" });
        updateExpenseState(index, newExpense);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const updateExpenseType = (
    id: Expense["_id"],
    type: Expense["type"]
  ): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const index = expenses.findIndex((expense: Expense) => expense._id === id);
    const expense = expenses[index];

    const newExpense = { ...expense, type };
    const params = getRequestOptions("POST", { id, type });
    fetch(BASE_URL + "update-expense-type", params)
      .then((res) => res.json())
      .then((res) => {
        toast(
          `Successfully updated expense type from ${expense.type} to ${type}`,
          { type: "success" }
        );
        updateExpenseState(index, newExpense);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const updateExpense = (genericExpense: GenericExpense): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = expenses.findIndex(
      (exp: Expense) => exp._id === genericExpense._id
    );
    const expense: Expense = {
      _id: genericExpense._id,
      amount: genericExpense.amount,
      expenseName: genericExpense.expenseName,
      sheet: genericExpense.sheet!,
      type: genericExpense.type,
      toWithdraw: genericExpense.toWithdraw,
      isPaid: genericExpense.isPaid!,
      installmentId: genericExpense.installmentId || "",
    };

    const params = getRequestOptions("POST", expense);
    fetch(BASE_URL + "update-expense", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Successfully updated expense ${expense.expenseName}`, {
          type: "success",
        });
        updateExpenseState(index, expense);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const updateExpenseState = (index: number, expense: Expense): void => {
    const newExpenses = [...expenses];
    newExpenses[index] = expense;
    dispatch(setExpenses(newExpenses));
    setExpense(newExpenses);
  };

  const deleteExpense = (id: Expense["_id"]): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const index = expenses.findIndex((expense: Expense) => expense._id === id);
    const expense = expenses[index];
    const newExpenses = [...expenses];
    const params = getRequestOptions("DELETE", { id });
    fetch(BASE_URL + "expense", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`expense ${expense.expenseName} deleted successfully!`, {
          type: "success",
        });

        newExpenses.splice(index, 1);
        dispatch(setExpenses(newExpenses));
        setExpense(newExpenses);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const saveExpense = (
    genericExpense: GenericExpense,
    callback: () => void
  ): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const expense: Expense = {
      _id: "",
      amount: genericExpense.amount,
      expenseName: genericExpense.expenseName,
      sheet: props.sheet._id,
      type: genericExpense.type,
      toWithdraw: genericExpense.toWithdraw,
      isPaid: genericExpense.isPaid!,
      installmentId: genericExpense.installmentId || "",
    };

    const params = getRequestOptions("POST", expense);
    fetch(BASE_URL + "expense", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`expense ${expense.expenseName} added successfully!`, {
          type: "success",
        });

        const newExpenses = [...expenses, { ...expense, _id: res._id }];
        dispatch(setExpenses(newExpenses));
        setExpense(newExpenses);
        callback();
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  if (!expenses[0] || expenses[0]._id === "") {
    <div>Loading ...</div>;
  }

  return (
    <div className="expenses-component">
      {!expenses ? (
        ""
      ) : (
        <div>
          <ExpensesWrapper type={ExpenseComponentType.Sheet}>
            {expenses.map((expense: Expense) => {
              return (
                <ExpenseBody
                  expense={expense}
                  key={expense._id}
                  updateIsPaid={(id: Expense["_id"]) => updateIsPaid(id)}
                  updateToWithdraw={(id: Expense["_id"]) =>
                    updateToWithdraw(id)
                  }
                  deleteExpense={(id: Expense["_id"]) => deleteExpense(id)}
                  updateExpenseType={(
                    id: Expense["_id"],
                    type: Expense["type"]
                  ) => updateExpenseType(id, type)}
                  updateExpense={(expense: GenericExpense) =>
                    updateExpense(expense)
                  }
                  type={props.type}
                  month={props.sheet.month}
                  year={props.sheet.year}
                />
              );
            })}

            {props.type === ExpenseComponentType.Sheet ? (
              <ExpenseAdd
                type={ExpenseAddComponentType.Expense}
                saveExpense={(expense: GenericExpense, callback: () => void) =>
                  saveExpense(expense, callback)
                }
              />
            ) : (
              ""
            )}
          </ExpensesWrapper>
        </div>
      )}
    </div>
  );
}

interface Props {
  sheet: Sheet;
  type: ExpenseComponentType.Sheet | ExpenseComponentType.Sheets;
}

export default Expenses;
