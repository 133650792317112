import { useEffect, useState } from "react";
import "./main.scss";
import { Routes, Route } from "react-router-dom";
import SheetsRoute from "../../component-routes/sheets/sheets";
import CalendarRoute from "../../component-routes/calendar/calendar";
import Header from "../header/header";
import Nav from "../nav/nav";
import ThemeContext, { ThemeContextModel } from "../../theme-context";
import { ToastContainer } from "react-toastify";
import Footer from "../footer/footer";
import SheetRoute from "../../component-routes/sheet/sheet";
import SavedExpensesRoute from "../../component-routes/saved-expenses/saved-expenses";
import TemplatesRoute from "../../component-routes/templates/templates";
import AddSheetRoute from "../../component-routes/add-sheet/add-sheet";
import InstallmentsRoute from "../../component-routes/installments/installments";
import DeviceLimit from "../device-limit/device-limit";
import BrowserLimit from "../browser-limit/browser-limit";
import { useDispatch } from "react-redux";
import { entities } from "../../data/entities";
import { setEntity } from "../../slices/sheet";
import { Entity } from "../../models/entities";

function Main() {
  const [theme, setTheme] = useState("light" as "light" | "dark");
  const [demo, setIsDemo] = useState(false);

  const dispatch = useDispatch();

  const getContextValue = (): ThemeContextModel => {
    return {
      theme,
      demo,
      updateTheme: () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
      },
    };
  };

  useEffect(() => {
    const entityKey = localStorage.getItem("entity");
    const entity = entities.find((entity: Entity) => entity.key === entityKey);
    if (entityKey && entity?.key) {
      dispatch(setEntity(entity));
    }

    if (process.env.REACT_APP_DEMO && process.env.REACT_APP_DEMO === "true") {
      setIsDemo(true);
    }
  }, [dispatch]);

  return (
    <ThemeContext.Provider value={getContextValue()}>
      <div className={"body-root _" + theme}>
        <div className="left">
          <Nav />
        </div>
        <div className="right">
          <ToastContainer
            theme={theme === "dark" ? "dark" : "light"}
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="body-header">
            <Header />
          </div>
          <main className="body-main _shadow">
            <Routes>
              <Route path="/" element={<CalendarRoute />}></Route>
              <Route
                path="/sheet/:entity/:month/:year"
                element={<SheetsRoute />}
              ></Route>

              <Route
                path="/sheet/:entity/:month/:year/:index"
                element={<SheetRoute />}
              ></Route>
              <Route path="/expenses" element={<SavedExpensesRoute />}></Route>

              <Route path="/templates" element={<TemplatesRoute />}></Route>
              <Route path="/sheet/add" element={<AddSheetRoute />}></Route>
              <Route
                path="/installments"
                element={<InstallmentsRoute />}
              ></Route>
            </Routes>
            <Footer />
          </main>
        </div>
        <DeviceLimit />
        <BrowserLimit />
      </div>
    </ThemeContext.Provider>
  );
}
export default Main;
