import { Earning } from "../models/earnings";
import { Expense } from "../models/expenses";

export function getTotalEarnings(earnings: Earning[]): number {
  let total = 0;
  earnings.forEach((earning: Earning) => {
    total += earning.amount;
  });

  return total;
}

export function getTotal(expenses: Expense[]): number {
  let total = 0;
  expenses.forEach((expense: Expense) => {
    total += expense.amount;
  });

  return total;
}

export function getTotalType(
  expenses: Expense[],
  type: Expense["type"]
): number {
  const filteredExpenses = expenses.filter(
    (expense: Expense) => expense.type === type
  );

  return getTotal(filteredExpenses);
}

export function getTotalWithdraw(expenses: Expense[]): number {
  const filteredExpenses = expenses.filter(
    (expense: Expense) => expense.toWithdraw
  );

  return getTotal(filteredExpenses);
}

export function getRemaining(expenses: Expense[], earnings: Earning[]): number {
  const totalEarnings = getTotalEarnings(earnings);
  const totalExpenses = getTotal(expenses);

  return totalEarnings - totalExpenses;
}

export function getPercentage(earnings: Earning[], value: number): number {
  const total = getTotalEarnings(earnings);
  if (total === 0) {
    return 0;
  }

  return Math.round((100 * value) / total);
}
