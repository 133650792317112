import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EntityFilter from "../../components-generic/entity-filter/entity-filter";
import Heading from "../../components-generic/heading/heading";

import { BASE_URL } from "../../data/constants";
import { Entity } from "../../models/entities";
import { Installment } from "../../models/installments";
import { getEntity, setEntity } from "../../slices/sheet";
import ThemeContext from "../../theme-context";
import {
  handleDemoError,
  handleEndpointError,
} from "../../utils/handle-errors";
import { getQueryParams, getRequestOptions } from "../../utils/requests";
import AddInstallment from "./add-installment/add-installment";
import InstallmentItem from "./installment-item/installment-item";
import "./installments.scss";

function Installments(props: Props) {
  const dispatch = useDispatch();
  const entity = useSelector(getEntity);
  const theme = useContext(ThemeContext);

  const [total, setTotal] = useState(0);
  const [installments, setInstallments] = useState([] as Installment[]);

  useEffect(() => {
    const params = getQueryParams({ entity: entity.key });
    fetch(BASE_URL + "installment" + params)
      .then((res) => res.json())
      .then((res) => {
        setInstallments(res.reverse());
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity]);

  useEffect(() => {
    let totalAmount = 0;
    installments.forEach((installment: Installment) => {
      totalAmount += installment.total;
    });

    setTotal(totalAmount);
  }, [installments]);

  const onEntityChange = (entity: Entity): void => {
    dispatch(setEntity(entity));
  };

  const saveInstallment = (
    installment: Installment,
    callback: () => void
  ): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const params = getRequestOptions("POST", installment);
    fetch(BASE_URL + "installment", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Successfully added installment ${installment.expenseName}`, {
          type: "success",
        });

        callback();

        const newInstallments = [
          ...installments,
          { ...installment, _id: res._id },
        ];
        setInstallments(newInstallments);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const deleteInstallment = (id: Installment["_id"]): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const index = installments.findIndex(
      (installment) => installment._id === id
    );
    const installment = installments[index];

    const params = getRequestOptions("DELETE", { id });
    fetch(BASE_URL + "installment", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Successfully deleted installment ${installment.expenseName}`, {
          type: "success",
        });

        const newInstallments = [...installments];
        newInstallments.splice(index, 1);
        setInstallments(newInstallments);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  return (
    <div className="installments-component">
      <Heading content="Installments" />
      <div className="_page-height">
        <EntityFilter
          entityKey={entity.key}
          onChange={(entity: Entity) => onEntityChange(entity)}
        />
        <div className="installments-table _margin-top _table">
          <div className="_row -header">
            <div className="_cell -amount">Total</div>
            <div className="_cell -amount">Monthly</div>
            <div className="_cell -expense">Expense Name</div>
            <div className="_cell -dates">Payment</div>
            <div className="_cell -date">Months</div>
            <div className="_cell -progress">Progress</div>
            <div className="_cell -buttons"></div>
            <div className="_cell -total">
              total: <span className="value">₱{total}</span>
            </div>
          </div>

          {installments.map((installment: Installment) => {
            return (
              <InstallmentItem
                key={installment._id}
                installment={installment}
                deleteInstallment={(id: Installment["_id"]) =>
                  deleteInstallment(id)
                }
              />
            );
          })}

          <div className="_row -message">
            <p className="-bold">Add new installment</p>
          </div>

          <AddInstallment
            saveInstallment={(installment: Installment, callback: () => void) =>
              saveInstallment(installment, callback)
            }
            entity={entity.key}
          />
          <div className="_row -empty"></div>
          <div className="_row -footer"></div>
        </div>
      </div>
    </div>
  );
}

export default Installments;

interface Props {}
