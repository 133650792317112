import { Entity } from "../../models/entities";
import { Month } from "../../models/months";
import { useDispatch } from "react-redux";
import { setSheetKey } from "../../slices/sheet";
import { useContext, useEffect, useState } from "react";
import SheetsHeading from "../../components-generic/sheets-heading/sheets-heading";
import { getQueryParams, getRequestOptions } from "../../utils/requests";
import { BASE_URL } from "../../data/constants";
import SheetsList from "./sheets-list/sheets-list";
import { Sheet } from "../../models/sheets";
import "./sheets.scss";
import PageNav from "../../components-generic/page-nav/page-nav";
import { toast } from "react-toastify";
import DateButtons from "../../components-generic/date-buttons/date-buttons";
import {
  handleDemoError,
  handleEndpointError,
} from "../../utils/handle-errors";
import ThemeContext from "../../theme-context";

function Sheets(props: Props) {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const [sheets, setSheets] = useState([] as Sheet[]);

  const { entity, month, year } = props;

  useEffect(() => {
    dispatch(setSheetKey({ entity, month, year }));
  });

  const deleteSheet = (id: Sheet["_id"]) => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = sheets.findIndex((sheet: Sheet) => sheet._id === id);
    const sheet = sheets[index];
    const params = getRequestOptions("DELETE", { id });
    fetch(BASE_URL + "sheet", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Successfully deleted sheet ${sheet.label} successfully!`, {
          type: "success",
        });

        const newSheets = [...sheets];
        newSheets.splice(index, 1);
        setSheets(newSheets);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  useEffect(() => {
    const params = getQueryParams({ entity, month, year });
    fetch(BASE_URL + "sheet" + params)
      .then((res) => res.json())
      .then((res) => {
        setSheets(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity, month, year]);

  return (
    <div className="sheets-component">
      <SheetsHeading />
      <div className="sheets-flex _page-flex">
        <div className="_grow">
          {sheets && sheets[0] ? (
            <SheetsList
              sheets={sheets}
              deleteSheet={(id: Sheet["_id"]) => deleteSheet(id)}
            />
          ) : (
            <div className="_empty">
              <p>0 Sheets Available</p>
            </div>
          )}
        </div>
        <div className="_fixed _margin-left">
          <PageNav />
        </div>
      </div>
      <div className="sheets-footer">
        <DateButtons
          entity={props.entity}
          month={props.month}
          year={props.year}
        />
      </div>
    </div>
  );
}

interface Props {
  entity: Entity["key"];
  month: Month["key"];
  year: number;
}

export default Sheets;
