import { useEffect, useState } from "react";
import { Expense, ExpenseComponentType } from "../../models/expenses";
import ButtonSvg from "../button-svg/button-svg";
import Checkbox from "../checkbox/checkbox";
import ExpenseType from "./expense-type";
import { DebounceInput } from "react-debounce-input";
import { useSelector } from "react-redux";
import { getInstallments, getSavedExpenses } from "../../slices/sheet";
import { expensesToOptions } from "../../utils/auto-complete";
import { Option } from "../../models/auto-complete";
import AutoCompleteWrapper from "../auto-complete/auto-complete-wrapper";
import { GenericExpense } from "../../models/generic-expenses";
import { Installment } from "../../models/installments";
import { getMonthsProgress } from "../../utils/dates";
import { useNavigate } from "react-router";
import { Month } from "../../models/months";

function ExpenseBody(props: Props) {
  const [amount, setAmount] = useState(props.expense.amount);
  const [expenseName, setExpenseName] = useState(props.expense.expenseName);

  const [errorMessage, setErrorMessage] = useState("");
  const [errorAmount, setErrorAmount] = useState(false);
  const [errorExpenseName, setErrorExpenseName] = useState(false);

  const savedExpenses = useSelector(getSavedExpenses);
  const installments = useSelector(getInstallments);
  const [installmentMessage, setInstallmentMessage] = useState("");

  const autoCompleteOptions: Option[] = expensesToOptions(savedExpenses);

  let navigate = useNavigate();

  const isDirty = (): boolean => {
    if (
      expenseName !== props.expense.expenseName ||
      amount === 0 ||
      amount !== props.expense.amount
    ) {
      return true;
    }

    return false;
  };

  const updateExpense = (): void => {
    if (expenseName === "") {
      setErrorMessage("Expense Name is required");
      setErrorExpenseName(true);
      return;
    }

    if (amount < 1) {
      setErrorMessage("Amount is required");
      setErrorAmount(true);
      return;
    }

    const expense = {
      ...props.expense,
      amount,
      expenseName,
    };
    props.updateExpense?.(expense);
  };

  let additionalClassNames = "";
  if (props.expense.isPaid) {
    additionalClassNames += " -checked";
  }

  if (
    props.type === ExpenseComponentType.Templates ||
    props.type === ExpenseComponentType.AddSheetTemplates
  ) {
    additionalClassNames += " -template";
  }

  if (props.type === ExpenseComponentType.AddSheetInstallment) {
    additionalClassNames += " -installment";
  }

  useEffect(() => {
    if (
      props.expense.type === "installment" &&
      props.expense.installmentId !== "" &&
      props.month !== undefined &&
      props.year
    ) {
      const index = installments.findIndex(
        (installment: Installment) =>
          installment._id === props.expense.installmentId
      );

      if (index !== -1) {
        const installment = installments[index];
        const { start, end } = getMonthsProgress(
          installment.startMonth,
          installment.startYear,
          installment.endMonth,
          installment.endYear,
          props.month,
          props.year
        );
        setInstallmentMessage(`${start}/${end}`);
      }
    }
  }, [
    props.month,
    props.year,
    props.expense.type,
    props.expense.installmentId,
    installments,
  ]);

  return (
    <div>
      {isDirty() ? (
        <div className="_row -message -warn">
          <p>Unsaved changes</p>
        </div>
      ) : (
        ""
      )}

      {errorMessage !== "" ? (
        <div className="_row -message -error">
          <p>{errorMessage}</p>
        </div>
      ) : (
        ""
      )}

      <div className={"expenses-body _row " + additionalClassNames}>
        <div className="_cell -type">
          <ExpenseType
            type={props.expense.type}
            id={props.expense._id}
            showContextMenu={
              props.type !== ExpenseComponentType.Sheets &&
              props.type !== ExpenseComponentType.Templates &&
              props.type !== ExpenseComponentType.AddSheetInstallment &&
              props.type === ExpenseComponentType.Sheet &&
              props.expense.type !== "installment"
            }
            updateExpenseType={(type: Expense["type"]) =>
              props.updateExpenseType?.(props.expense._id, type)
            }
          />
        </div>
        {props.type === ExpenseComponentType.Sheet ||
        props.type === ExpenseComponentType.Sheets ? (
          <div className="_cell -checkbox">
            <Checkbox
              checked={props.expense.isPaid!}
              onClick={() => props.updateIsPaid?.(props.expense._id)}
              name="isPaid"
            />
          </div>
        ) : (
          ""
        )}

        {props.type === ExpenseComponentType.AddSheetTemplates ? (
          <div className="_cell -checkbox">
            <Checkbox checked={true} name="isPaid" disabled={true} />
          </div>
        ) : (
          ""
        )}
        {props.type === ExpenseComponentType.AddSheetSaved ||
        props.type === ExpenseComponentType.AddSheetInstallment ? (
          <div className="_cell -checkbox">
            <Checkbox
              checked={props.expense.include!}
              name="include"
              onClick={() => props.updateToInclude?.(props.expense._id)}
            />
          </div>
        ) : (
          ""
        )}
        <div className="_cell -checkbox">
          <Checkbox
            checked={props.expense.toWithdraw}
            onClick={() => props.updateToWithdraw?.(props.expense._id)}
            name="toWithdraw"
            disabled={
              props.type === ExpenseComponentType.Templates ||
              props.type === ExpenseComponentType.TemplatesSaved ||
              props.type === ExpenseComponentType.AddSheetTemplates ||
              props.type === ExpenseComponentType.AddSheetSaved
            }
          />
        </div>

        <div className="_cell -text -amount">
          {props.expense.isPaid ||
          props.type === ExpenseComponentType.Sheets ||
          props.type === ExpenseComponentType.Templates ||
          props.type === ExpenseComponentType.TemplatesSaved ||
          props.type === ExpenseComponentType.AddSheetInstallment ? (
            <span> ₱ {props.expense.amount}</span>
          ) : (
            <span>
              ₱{" "}
              <DebounceInput
                className={"_clearinput " + (errorAmount ? "-error" : "")}
                debounceTimeout={300}
                onChange={(event) => {
                  const value = event.target.value || "0";
                  setAmount(parseInt(value, 0));
                  setErrorMessage("");
                  setErrorAmount(false);
                }}
                type="number"
                value={amount}
              />
              {amount !== props.expense.amount ? (
                <ButtonSvg
                  onClick={() => {
                    setAmount(props.expense.amount);
                    setErrorMessage("");
                    setErrorAmount(false);
                  }}
                  svg="undo"
                  tip="undo changes"
                  label="Undo changes"
                ></ButtonSvg>
              ) : (
                ""
              )}
            </span>
          )}
        </div>
        <div className="_cell -text -expense">
          {props.expense.isPaid ||
          props.type === ExpenseComponentType.Sheets ||
          props.type === ExpenseComponentType.Templates ||
          props.type === ExpenseComponentType.TemplatesSaved ||
          props.type === ExpenseComponentType.AddSheetInstallment ||
          (props.type === ExpenseComponentType.Sheet &&
            props.expense.type === "installment") ? (
            props.expense.expenseName
          ) : (
            <span className="_w-100">
              {props.type === ExpenseComponentType.SavedExpenses ? (
                <DebounceInput
                  className={
                    "_clearinput " + (errorExpenseName ? "-error" : "")
                  }
                  type="text"
                  placeholder="Expense name..."
                  autoComplete="off"
                  debounceTimeout={250}
                  onChange={(event) => {
                    setExpenseName(event.target.value);
                    setErrorMessage("");
                    setErrorExpenseName(false);
                  }}
                  value={expenseName}
                />
              ) : (
                <AutoCompleteWrapper
                  options={autoCompleteOptions}
                  inputValue={expenseName}
                  inputPlaceholder="Expense name..."
                  inputClassName={
                    "_clearinput " + (errorExpenseName ? "-error" : "")
                  }
                  onInputChange={(event) => {
                    setExpenseName(event.target.value);
                    setErrorMessage("");
                    setErrorExpenseName(false);
                  }}
                  onOptionSelected={(option) => {
                    const { type, expenseName, amount, toWithdraw } = option;
                    props.updateExpense?.({
                      ...props.expense,
                      type,
                      expenseName,
                      amount,
                      toWithdraw,
                    });

                    setExpenseName(expenseName);
                    setAmount(amount);
                  }}
                />
              )}
              {expenseName !== props.expense.expenseName ? (
                <ButtonSvg
                  onClick={() => {
                    setExpenseName(props.expense.expenseName);
                    setErrorMessage("");
                    setErrorExpenseName(false);
                  }}
                  svg="undo"
                  tip="undo changes"
                  label="Undo changes"
                ></ButtonSvg>
              ) : (
                ""
              )}
            </span>
          )}

          {/* installment data */}
          {installmentMessage ? (
            <p className="installment-data">({installmentMessage})</p>
          ) : (
            ""
          )}
        </div>
        <div className="_cell -buttons">
          {/* update expense */}
          {isDirty() ? (
            <ButtonSvg
              svg="save"
              tip="update expense"
              label="Update Expense"
              onClick={() => {
                updateExpense();
              }}
            ></ButtonSvg>
          ) : (
            ""
          )}

          {/* navigate to installment */}
          {props.expense.type === "installment" &&
          props.expense.installmentId !== "" ? (
            <ButtonSvg
              svg="installment"
              tip="navigate to installments"
              label="navigate to installments"
              onClick={() => {
                navigate(`../installments`, { replace: true });
              }}
            ></ButtonSvg>
          ) : (
            ""
          )}

          {/* add expense */}
          {props.type === ExpenseComponentType.TemplatesSaved ? (
            <ButtonSvg
              svg="add"
              tip="Add expense to template"
              label="Add expense to template"
              onClick={() => {
                props.addExpense?.(props.expense._id);
              }}
            ></ButtonSvg>
          ) : (
            ""
          )}

          {/* delete expense */}
          {props.deleteExpense &&
          props.type !== ExpenseComponentType.Sheets &&
          props.type !== ExpenseComponentType.TemplatesSaved ? (
            <ButtonSvg
              svg="delete"
              tip={
                props.type === ExpenseComponentType.Templates
                  ? "Remove expense from template"
                  : "Delete expense"
              }
              label={
                props.type === ExpenseComponentType.Templates
                  ? "Remove expense from template"
                  : "Delete expense"
              }
              onClick={() => {
                props.deleteExpense?.(props.expense._id);
              }}
            ></ButtonSvg>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

interface Props {
  expense: GenericExpense;
  month?: Month["key"];
  year?: number;
  updateIsPaid?: (id: GenericExpense["_id"]) => void;
  updateToWithdraw?: (id: GenericExpense["_id"]) => void;
  deleteExpense?: (id: GenericExpense["_id"]) => void;
  addExpense?: (id: GenericExpense["_id"]) => void;
  updateToInclude?: (id: GenericExpense["_id"]) => void;
  updateExpenseType?: (
    id: GenericExpense["_id"],
    type: GenericExpense["type"]
  ) => void;
  updateExpense?: (expense: GenericExpense) => void;
  type: ExpenseComponentType;
}

export default ExpenseBody;
