import { entities } from "../../data/entities";
import { Entity } from "../../models/entities";
import "./entity-filter.scss";

function EntityFilter(props: Props) {
  const onChange = (value: string): void => {
    const index = entities.findIndex((entity) => entity.key === value);
    const entity = entities[index];
    props.onChange(entity);
  };
  return (
    <div className="entity-filter">
      <select
        className="_input year"
        onChange={(val) => onChange(val.target.value)}
        value={props.entityKey}
      >
        {entities.map((entity) => (
          <option key={entity.key} value={entity.key}>
            {" "}
            {entity.label}{" "}
          </option>
        ))}
      </select>
    </div>
  );
}

export default EntityFilter;

interface Props {
  entityKey: Entity["key"];
  onChange: (entity: Entity) => void;
}
