import React from "react";
import "./sub-heading.scss";

function SubHeading(props: Props) {
  return <h2 className="subheading-text">{props.content}</h2>;
}

export default SubHeading;

interface Props {
  content: string;
}
