import { BASE_URL } from "../../../data/constants";
import { Earning } from "../../../models/earnings";
import { Sheet } from "../../../models/sheets";
import { getQueryParams, getRequestOptions } from "../../../utils/requests";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getEarnings, setEarnings } from "../../../slices/sheet";
import { useContext, useEffect } from "react";

import createTrigger from "react-use-trigger";
import useTrigger from "react-use-trigger/useTrigger";
import SubHeading from "../../../components-generic/sub-heading/sub-heading";
import AddEarning from "./add-earning";
import EarningComponent from "./earning";

import "./earnings.scss";
import {
  handleDemoError,
  handleEndpointError,
} from "../../../utils/handle-errors";
import ThemeContext from "../../../theme-context";

const requestTrigger = createTrigger();

function Earnings(props: Props) {
  const dispatch = useDispatch();
  const earnings = useSelector(getEarnings);
  const theme = useContext(ThemeContext);

  const deleteEarning = (id: Earning["_id"]): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const params = getRequestOptions("DELETE", { id });

    fetch(BASE_URL + "earning", params)
      .then((res) => res.json())
      .then((res) => {
        toast("1 earning deleted successfully!", {
          type: "success",
        });
        requestTrigger();
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const requestTriggerValue = useTrigger(requestTrigger);

  useEffect(() => {
    const params = getQueryParams({ sheet: props.sheet });
    fetch(BASE_URL + "earning" + params)
      .then((res) => res.json())
      .then((res) => {
        dispatch(setEarnings(res));
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [requestTriggerValue, props.sheet, dispatch]);

  if (!earnings[0] || earnings[0]._id === "") {
    <div>Loading ...</div>;
  }

  return (
    <div className="earnings-component">
      <SubHeading content="Earnings" />
      {earnings
        ? earnings.map((earning: Earning) => {
            return (
              <EarningComponent
                key={earning._id}
                earning={earning}
                deleteEarning={() => deleteEarning(earning._id)}
              />
            );
          })
        : ""}

      <AddEarning sheet={props.sheet} />
    </div>
  );
}

export default Earnings;

interface Props {
  sheet: Sheet["_id"];
}
