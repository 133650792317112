export function getRequestOptions(
  method: "POST" | "GET" | "DELETE",
  params: object
): { method: string; headers: any; body: string } {
  return {
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };
}

export function getQueryParams(params: { [key: string]: any }): string {
  let query = "?";
  const keys = Object.keys(params);
  keys.forEach((key, index) => {
    query += `${key}=${params[key]}`;

    if (index !== keys.length - 1) {
      query += "&";
    }
  });

  return query;
}
