import "./heading.scss";

function Heading(props: Props) {
  return <h1 className="heading-text">{props.content}</h1>;
}

export default Heading;

interface Props {
  content: string;
}
