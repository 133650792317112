import { toast } from "react-toastify";

/**
 * TODO: make error messages dynamic and more clear
 * @param message error message
 */
export function handleEndpointError(message?: string): void {
  toast(
    message ? message : `Something went wrong. Please try again in a while.`,
    { type: "error" }
  );
}

export function handleDemoError(): void {
  toast(
    `This is a demo version of Budget Sheets. Only GET method is allowed.`,
    { type: "info" }
  );
}
