import { Component } from "react";
import "./calendar.scss";

import { months } from "../../data/months";
import { entities } from "../../data/entities";

import { Link } from "react-router-dom";
import * as dayjs from "dayjs";
import Heading from "../../components-generic/heading/heading";

class Calendar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startYear: 2021,
      currentYear: 0,
      year: 0,
      month: 0,
    };
  }

  componentDidMount(): void {
    const month = dayjs.default().get("month");
    const year = dayjs.default().get("year");
    this.setState({ month, year, currentYear: year });
  }

  getMonthClass(index: number): string {
    if (
      index === this.state.month &&
      this.state.year === this.state.currentYear
    ) {
      return "month -current";
    }

    if (
      (index < this.state.month &&
        this.state.year === this.state.currentYear) ||
      this.state.year < this.state.currentYear
    ) {
      return "month -prev";
    }

    return "month";
  }

  getMonths() {
    return months.map((month, index) => {
      return (
        <div className={this.getMonthClass(index)} key={index}>
          <p className="label">
            {month.label} {this.state.year}
          </p>
          <div className="entities-container">
            {entities.map((entity) => {
              return (
                <Link
                  key={entity.key}
                  className="entity"
                  to={`/sheet/${entity.key}/${month.key}/${this.state.year}`}
                >
                  {entity.label}
                </Link>
              );
            })}
          </div>
        </div>
      );
    });
  }

  selectChanges(value: string) {
    const year = parseInt(value);
    this.setState({ year });
  }

  getFilter() {
    const diff = this.state.currentYear - this.state.startYear;
    return (
      <div className="filter-container">
        <select
          className="_input year"
          onChange={(val) => this.selectChanges(val.target.value)}
          value={this.state.year}
        >
          {[...Array(diff + 2)].map((current, i) => (
            <option key={i}> {i + this.state.startYear} </option>
          ))}
        </select>
      </div>
    );
  }

  render() {
    return (
      <div className="calendar-component">
        <Heading content={this.state.year + " Calendar"}></Heading>
        {this.state.year ? this.getFilter() : ""}
        <div className="months-container">{this.getMonths()}</div>
      </div>
    );
  }
}

interface Props {}
interface State {
  year: number;
  month: number;
  startYear: number;
  currentYear: number;
}

export default Calendar;
