import { months } from "../data/months";
import { Month, MonthData } from "../models/months";
import * as dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export function getMonthIndex(key: Month["key"]): number {
  const monthIndex = months.findIndex((month) => month.key === key);
  const month = months[monthIndex];

  if (month) {
    return monthIndex;
  }

  return 0;
}

export function getMonthFromIndex(monthIndex: number): string {
  return months[monthIndex].key;
}

export function getMonthLabel(key: Month["key"]): string {
  const monthIndex = months.findIndex((month) => month.key === key);
  const month = months[monthIndex];

  if (month) {
    return month.label;
  }

  return "";
}

export function getMonthsProgress(
  startMonth: Month["key"],
  startYear: number,
  endMonth: Month["key"],
  endYear: number,
  currentMonth: Month["key"],
  currentYear: number
): {
  start: number;
  end: number;
} {
  const startMonthIndex = getMonthIndex(startMonth);
  const endMonthIndex = getMonthIndex(endMonth);
  const currentMonthIndex = getMonthIndex(currentMonth);

  const currentDate = dayjs.default(`${currentYear}-${currentMonthIndex}-01`);
  const startDate = dayjs.default(`${startYear}-${startMonthIndex}-01`);
  const endDate = dayjs.default(`${endYear}-${endMonthIndex}-01`);

  const diff = endDate.diff(startDate, "month");
  const diffProgress = currentDate.isAfter(`${startYear}-${startMonth}-01`)
    ? currentDate.diff(startDate, "month")
    : 0;

  return { start: Math.min(diffProgress, diff), end: diff };
}

export function getPreviousMonth(month: Month["key"], year: number): MonthData {
  const index = getMonthIndex(month);

  let newMonth = months[index - 1];
  let newYear = year;
  if (index === 0) {
    newMonth = months[months.length - 1];
    newYear = year - 1;
  }

  return {
    month: newMonth.key,
    year: newYear,
    label: `${newMonth.label} ${newYear}`,
  };
}

export function getNextMonth(month: Month["key"], year: number): MonthData {
  const index = getMonthIndex(month);

  let newMonth = months[index + 1];
  let newYear = year;
  if (index === months.length - 1) {
    newMonth = months[0];
    newYear = year + 1;
  }

  return {
    month: newMonth.key,
    year: newYear,
    label: `${newMonth.label} ${newYear}`,
  };
}

export function isDateInBetween(
  startMonth: Month["key"],
  startYear: number,
  endMonth: Month["key"],
  endYear: number,
  currentMonth: Month["key"],
  currentYear: number
): boolean {
  const currentMonthIndex = getMonthIndex(currentMonth);
  const startMonthIndex = getMonthIndex(startMonth);
  const endMonthIndex = getMonthIndex(endMonth);

  const currentDate = `${currentYear}-${currentMonthIndex}-2`;
  const startDate = `${startYear}-${startMonthIndex}-1`;
  const endDate = `${endYear}-${endMonthIndex}-3`;
  return dayjs
    .default(currentDate)
    .isBetween(dayjs.default(startDate), dayjs.default(endDate));
}
