import { Expense, SavedExpense } from "../models/expenses";
import { Option } from "../models/auto-complete";

export function expensesToOptions(
  expenses: Expense[] | SavedExpense[]
): Option[] {
  return expenses.map((expense: Expense | SavedExpense) => ({
    amount: expense.amount,
    expenseName: expense.expenseName,
    toWithdraw: expense.toWithdraw,
    type: expense.type,
  }));
}
