import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ExpenseBody from "../../../components-generic/expenses/expense-body";
import ExpensesWrapper from "../../../components-generic/expenses/expenses-wrapper";
import { BASE_URL } from "../../../data/constants";
import { Entity } from "../../../models/entities";
import { ExpenseComponentType, SavedExpense } from "../../../models/expenses";
import { Template, TemplateExpense } from "../../../models/templates";
import ThemeContext from "../../../theme-context";
import {
  handleDemoError,
  handleEndpointError,
} from "../../../utils/handle-errors";
import { getQueryParams, getRequestOptions } from "../../../utils/requests";

function TemplateExpenses(props: Props) {
  const theme = useContext(ThemeContext);

  const [savedExpenses, setSavedExpenses] = useState([] as SavedExpense[]);
  const [templateExpenses, setTemplateExpenses] = useState(
    [] as TemplateExpense[]
  );
  const [total, setTotal] = useState(0);
  const [savedExpensesFiltered, setSavedExpensesFiltered] = useState(
    [] as SavedExpense[]
  );

  useEffect(() => {
    const params = getQueryParams({ entity: props.entity });
    fetch(BASE_URL + "saved-expense" + params)
      .then((res) => res.json())
      .then((res) => {
        setSavedExpenses(res);
        setSavedExpensesFiltered(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [props.entity]);

  useEffect(() => {
    const params = getQueryParams({ template: props.template._id });
    fetch(BASE_URL + "template-expense" + params)
      .then((res) => res.json())
      .then((res) => {
        setTemplateExpenses(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [props.template._id]);

  useEffect(() => {
    const newSavedExpensesFiltered = savedExpenses.filter(
      (savedExpense: SavedExpense) =>
        !templateExpenses.some(
          (templateExpense: TemplateExpense) =>
            savedExpense.expenseName === templateExpense.expenseName &&
            savedExpense.amount === templateExpense.amount
        )
    );
    setSavedExpensesFiltered(newSavedExpensesFiltered);

    let totalAmount = 0;
    templateExpenses.forEach((templateExpense: TemplateExpense) => {
      totalAmount += templateExpense.amount;
    });
    setTotal(totalAmount);
  }, [savedExpenses, templateExpenses]);

  const removeFromTemplate = (id: TemplateExpense["_id"]) => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = templateExpenses.findIndex(
      (templateExpense: TemplateExpense) => templateExpense._id === id
    );
    const templateExpense = templateExpenses[index];
    const newTemplateExpenses = [...templateExpenses];
    const params = getRequestOptions("DELETE", { id });
    fetch(BASE_URL + "template-expense", params)
      .then((res) => res.json())
      .then((res) => {
        toast(
          `Expense ${templateExpense.expenseName} removed from template ${props.template.label} successfully!`,
          { type: "success" }
        );

        newTemplateExpenses.splice(index, 1);
        setTemplateExpenses(newTemplateExpenses);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const addExpenseToTemplate = (id: SavedExpense["_id"]) => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = savedExpenses.findIndex(
      (savedExpense: SavedExpense) => savedExpense._id === id
    );
    const savedExpense = savedExpenses[index];

    const templateExpense: TemplateExpense = {
      _id: "",
      amount: savedExpense.amount,
      expenseName: savedExpense.expenseName,
      template: props.template._id,
      type: savedExpense.type,
      toWithdraw: savedExpense.toWithdraw,
    };

    const params = getRequestOptions("POST", templateExpense);
    fetch(BASE_URL + "template-expense", params)
      .then((res) => res.json())
      .then((res) => {
        toast(
          `Expense ${savedExpense.expenseName} added to template ${props.template.label} successfully!`,
          { type: "success" }
        );

        const newTemplateExpenses = [
          ...templateExpenses,
          { ...templateExpense, _id: res._id },
        ];
        setTemplateExpenses(newTemplateExpenses);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  return (
    <div className="templates-expenses">
      <ExpensesWrapper type={ExpenseComponentType.Templates} total={total}>
        {templateExpenses.map((templateExpense: TemplateExpense) => {
          return (
            <ExpenseBody
              expense={templateExpense}
              key={templateExpense._id}
              deleteExpense={(id: TemplateExpense["_id"]) =>
                removeFromTemplate(id)
              }
              type={ExpenseComponentType.Templates}
            />
          );
        })}

        {savedExpensesFiltered.map((savedExpense: SavedExpense) => {
          return (
            <ExpenseBody
              expense={savedExpense}
              key={savedExpense._id}
              addExpense={(id: SavedExpense["_id"]) => addExpenseToTemplate(id)}
              type={ExpenseComponentType.TemplatesSaved}
            />
          );
        })}
      </ExpensesWrapper>
    </div>
  );
}

interface Props {
  template: Template;
  entity: Entity["key"];
}

export default TemplateExpenses;
