import React, { useContext, useState } from "react";
import Button from "../../../components-generic/button/button";
import { DebounceInput } from "react-debounce-input";
import { getRequestOptions } from "../../../utils/requests";
import { Sheet } from "../../../models/sheets";
import { Earning } from "../../../models/earnings";
import { BASE_URL } from "../../../data/constants";
import { toast } from "react-toastify";
import { getEarnings, setEarnings } from "../../../slices/sheet";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDemoError,
  handleEndpointError,
} from "../../../utils/handle-errors";
import ThemeContext from "../../../theme-context";

function AddEarning(props: Props) {
  const earnings = useSelector(getEarnings);
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const [amount, setAmount] = useState(0);
  const [from, setFrom] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [errorAmount, setErrorAmount] = useState(false);
  const [errorFrom, setErrorFrom] = useState(false);

  const saveEarning = (): void => {
    if (from === "") {
      setErrorMessage("Earning from is required");
      setErrorFrom(true);
      return;
    }

    if (amount < 1) {
      setErrorMessage("Amount is required");
      setErrorAmount(true);
      return;
    }

    if (theme.demo) {
      handleDemoError();
      return;
    }

    const earning: Earning = {
      _id: "",
      sheet: props.sheet,
      from,
      amount,
    };

    const params = getRequestOptions("POST", earning);
    fetch(BASE_URL + "earning", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Earning from ${from} added successfully!`, {
          type: "success",
        });

        const newEarning = { ...earning, _id: res._id };
        const newEarnings = [...earnings, ...[newEarning]];
        dispatch(setEarnings(newEarnings));

        setAmount(0);
        setFrom("");
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  return (
    <div className="add-earning">
      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {errorMessage ? <p className="_error-message">{errorMessage}</p> : ""}
        <div className="input-container">
          <div className="input">
            ₱&nbsp;
            <DebounceInput
              className={"_input " + (errorAmount ? "-error" : "")}
              debounceTimeout={300}
              onChange={(event) => {
                const value = event.target.value || "0";
                setAmount(parseInt(value, 0));
                setErrorMessage("");
                setErrorAmount(false);
              }}
              type="number"
              value={amount}
            />
          </div>

          <div className="input">
            <DebounceInput
              className={"_input " + (errorFrom ? "-error" : "")}
              debounceTimeout={300}
              onChange={(event) => {
                setFrom(event.target.value);
                setErrorMessage("");
                setErrorFrom(false);
              }}
              value={from}
              placeholder="Business / Person..."
            />
          </div>
        </div>
      </form>
      <Button
        content="Add Earning"
        block={true}
        onClick={() => saveEarning()}
      />
    </div>
  );
}

export default AddEarning;

interface Props {
  sheet: Sheet["_id"];
}
