import React from "react";
import ButtonSvg from "../../../components-generic/button-svg/button-svg";
import { Earning } from "../../../models/earnings";

function EarningComponent(props: Props) {
  return (
    <div className="earning-item">
      <p className="amount">₱ {props.earning.amount}</p>
      <p className="from">&nbsp;from {props.earning.from}</p>
      <span className="delete">
        <ButtonSvg
          svg="delete"
          tip="Delete earning"
          label="Delete Earning"
          onClick={() => props.deleteEarning(props.earning._id)}
        />
      </span>
    </div>
  );
}

export default EarningComponent;

interface Props {
  earning: Earning;
  deleteEarning: (id: Earning["_id"]) => void;
}
