import React from "react";

export const defaultValue: ThemeContextModel = {
  theme: "light",
  demo: false,
  updateTheme: () => {},
};

const ThemeContext = React.createContext(defaultValue);
export default ThemeContext;

export interface ThemeContextModel {
  theme: "light" | "dark";
  demo: boolean;
  updateTheme: () => void;
}
