import ButtonSvg from "../../../components-generic/button-svg/button-svg";
import SubHeading from "../../../components-generic/sub-heading/sub-heading";
import { Template } from "../../../models/templates";
import "./templates-list.scss";

function TemplatesList(props: Props) {
  return (
    <div className="templates-list-component">
      <SubHeading content="Templates" />
      <div className="templates-list">
        {props.templates.map((template: Template, index: number) => {
          let className = "template";

          if (template._id === props.template._id) {
            className += " -active";
          }

          if (!template.active) {
            className += " -inactive";
          }

          return (
            <div key={template._id} className={className}>
              <button
                className="label _clearbutton"
                onClick={() => props.selectTemplate(template._id)}
              >
                {template.label}
              </button>
              {props.updateTemplateStatus ? (
                !template.active ? (
                  <ButtonSvg
                    svg="active"
                    tip="activate template"
                    label="Activate template"
                    onClick={() =>
                      props.updateTemplateStatus?.(template._id, true)
                    }
                  ></ButtonSvg>
                ) : (
                  <ButtonSvg
                    svg="inactive"
                    tip="set template to inactive"
                    label="Set template to inactive"
                    onClick={() =>
                      props.updateTemplateStatus?.(template._id, false)
                    }
                  ></ButtonSvg>
                )
              ) : (
                ""
              )}
              &nbsp;
              {props.deleteTemplate ? (
                <ButtonSvg
                  svg="delete"
                  tip="delete template"
                  label="Delete template"
                  onClick={() => props.deleteTemplate?.(template._id)}
                ></ButtonSvg>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

interface Props {
  templates: Template[];
  template: Template;
  selectTemplate: (id: Template["_id"]) => void;
  updateTemplateStatus?: (id: Template["_id"], active: boolean) => void;
  deleteTemplate?: (id: Template["_id"]) => void;
}

export default TemplatesList;
