import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EntityFilter from "../../components-generic/entity-filter/entity-filter";
import ExpenseAdd from "../../components-generic/expenses/expense-add";
import ExpenseBody from "../../components-generic/expenses/expense-body";
import ExpensesWrapper from "../../components-generic/expenses/expenses-wrapper";
import Heading from "../../components-generic/heading/heading";
import Legend from "../../components-generic/legend/legend";

import { BASE_URL } from "../../data/constants";
import { Entity } from "../../models/entities";
import {
  ExpenseAddComponentType,
  ExpenseComponentType,
  SavedExpense,
} from "../../models/expenses";
import { GenericExpense } from "../../models/generic-expenses";
import { getEntity, setEntity } from "../../slices/sheet";
import ThemeContext from "../../theme-context";
import {
  handleDemoError,
  handleEndpointError,
} from "../../utils/handle-errors";
import { getQueryParams, getRequestOptions } from "../../utils/requests";
import "./saved-expenses.scss";
function SavedExpenses(props: Props) {
  const dispatch = useDispatch();
  const entity = useSelector(getEntity);
  const theme = useContext(ThemeContext);

  const [savedExpenses, setSavedExpenses] = useState([] as SavedExpense[]);

  useEffect(() => {
    const params = getQueryParams({ entity: entity.key });
    fetch(BASE_URL + "saved-expense" + params)
      .then((res) => res.json())
      .then((res) => {
        setSavedExpenses(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity]);

  const onEntityChange = (entity: Entity): void => {
    dispatch(setEntity(entity));
  };

  const updateToWithdraw = (id: SavedExpense["_id"]): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = savedExpenses.findIndex(
      (savedExpense: SavedExpense) => savedExpense._id === id
    );
    const savedExpense = savedExpenses[index];

    const toWithdraw = !savedExpense.toWithdraw;
    const newSavedExpense = { ...savedExpense, toWithdraw };

    const params = getRequestOptions("POST", { id, toWithdraw });
    fetch(BASE_URL + "update-saved-expense-to-withdraw", params)
      .then((res) => res.json())
      .then((res) => {
        const message = toWithdraw
          ? `${savedExpense.expenseName} set to withdraw!`
          : `${savedExpense.expenseName} set to not withdraw!`;
        toast(message, { type: "success" });
        updateSavedExpenseState(index, newSavedExpense);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const deleteSavedExpense = (id: SavedExpense["_id"]): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = savedExpenses.findIndex(
      (savedExpense: SavedExpense) => savedExpense._id === id
    );
    const savedExpense = savedExpenses[index];
    const newExpenses = [...savedExpenses];
    const params = getRequestOptions("DELETE", { id });
    fetch(BASE_URL + "saved-expense", params)
      .then((res) => res.json())
      .then((res) => {
        toast(
          `saved expense ${savedExpense.expenseName} deleted successfully!`,
          {
            type: "success",
          }
        );

        newExpenses.splice(index, 1);
        setSavedExpenses(newExpenses);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const updateSavedExpenseState = (
    index: number,
    savedExpense: SavedExpense
  ): void => {
    const newExpenses = [...savedExpenses];
    newExpenses[index] = savedExpense;
    setSavedExpenses(newExpenses);
  };

  const updateSavedExpenseType = (
    id: SavedExpense["_id"],
    type: SavedExpense["type"]
  ): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = savedExpenses.findIndex(
      (savedExpense: SavedExpense) => savedExpense._id === id
    );
    const savedExpense = savedExpenses[index];

    const newExpense = { ...savedExpense, type };
    const params = getRequestOptions("POST", { id, type });
    fetch(BASE_URL + "update-saved-expense-type", params)
      .then((res) => res.json())
      .then((res) => {
        toast(
          `Successfully updated expense type from ${savedExpense.type} to ${type}`,
          { type: "success" }
        );
        updateSavedExpenseState(index, newExpense);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const updateSavedExpense = (genericExpense: GenericExpense): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const index = savedExpenses.findIndex(
      (exp: SavedExpense) => exp._id === genericExpense._id
    );
    const savedExpense: SavedExpense = {
      _id: genericExpense._id,
      amount: genericExpense.amount,
      expenseName: genericExpense.expenseName,
      type: genericExpense.type,
      toWithdraw: genericExpense.toWithdraw,
      entity: genericExpense.entity!,
    };

    const params = getRequestOptions("POST", savedExpense);
    fetch(BASE_URL + "update-saved-expense", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Successfully updated expense ${savedExpense.expenseName}`, {
          type: "success",
        });
        updateSavedExpenseState(index, savedExpense);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const saveSavedExpense = (
    genericExpense: GenericExpense,
    callback: () => void
  ): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }

    const savedExpense: SavedExpense = {
      _id: "",
      amount: genericExpense.amount,
      expenseName: genericExpense.expenseName,
      entity: entity.key,
      type: genericExpense.type,
      toWithdraw: genericExpense.toWithdraw,
    };

    const params = getRequestOptions("POST", savedExpense);
    fetch(BASE_URL + "saved-expense", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`saved expense ${savedExpense.expenseName} added successfully!`, {
          type: "success",
        });

        const newExpenses = [
          ...savedExpenses,
          { ...savedExpense, _id: res._id },
        ];
        setSavedExpenses(newExpenses);
        callback();
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  return (
    <div className="saved-expenses-component">
      <Heading content="Saved Expenses" />
      <div className="_page-flex">
        <div className="saved-expenses-left _grow">
          <EntityFilter
            entityKey={entity.key}
            onChange={(entity: Entity) => onEntityChange(entity)}
          />
          <div className="saved-expenses">
            <ExpensesWrapper type={ExpenseComponentType.SavedExpenses}>
              {savedExpenses.map((savedExpense: SavedExpense) => {
                return (
                  <ExpenseBody
                    expense={savedExpense}
                    key={savedExpense._id}
                    updateToWithdraw={(id: SavedExpense["_id"]) =>
                      updateToWithdraw(id)
                    }
                    deleteExpense={(id: SavedExpense["_id"]) =>
                      deleteSavedExpense(id)
                    }
                    updateExpenseType={(
                      id: SavedExpense["_id"],
                      type: SavedExpense["type"]
                    ) => updateSavedExpenseType(id, type)}
                    updateExpense={(expense: GenericExpense) =>
                      updateSavedExpense(expense)
                    }
                    type={ExpenseComponentType.SavedExpenses}
                  />
                );
              })}

              <ExpenseAdd
                type={ExpenseAddComponentType.SavedExpenses}
                saveExpense={(expense: GenericExpense, callback: () => void) =>
                  saveSavedExpense(expense, callback)
                }
              />
            </ExpensesWrapper>
          </div>
        </div>
        <div className="_fixed _margin-left">
          <Legend slim={true} />
        </div>
      </div>
    </div>
  );
}

export default SavedExpenses;

interface Props {}
