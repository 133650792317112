import { Month } from "../models/months";

export const months: Month[] = [
  {
    label: "January",
    key: "jan",
  },
  {
    label: "February",
    key: "feb",
  },
  {
    label: "March",
    key: "mar",
  },
  {
    label: "April",
    key: "apr",
  },
  {
    label: "May",
    key: "may",
  },
  {
    label: "June",
    key: "jun",
  },
  {
    label: "July",
    key: "jul",
  },
  {
    label: "August",
    key: "aug",
  },
  {
    label: "September",
    key: "sept",
  },
  {
    label: "October",
    key: "oct",
  },
  {
    label: "November",
    key: "nov",
  },
  {
    label: "December",
    key: "dec",
  },
];
