import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EntityFilter from "../../components-generic/entity-filter/entity-filter";
import Heading from "../../components-generic/heading/heading";
import Legend from "../../components-generic/legend/legend";
import { BLANK_TEMPLATE } from "../../data/blanks";
import { BASE_URL } from "../../data/constants";
import { Entity } from "../../models/entities";
import { Template } from "../../models/templates";
import { getEntity, setEntity } from "../../slices/sheet";
import ThemeContext from "../../theme-context";
import {
  handleDemoError,
  handleEndpointError,
} from "../../utils/handle-errors";
import { getQueryParams, getRequestOptions } from "../../utils/requests";
import AddTemplate from "./add-template/add-template";
import TemplateExpenses from "./template-expenses/template-expenses";
import TemplatesList from "./templates-list/templates-list";
import "./templates.scss";
function Templates() {
  const dispatch = useDispatch();
  const entity = useSelector(getEntity);
  const theme = useContext(ThemeContext);

  const [templates, setTemplates] = useState([] as Template[]);
  const [template, setTemplate] = useState(BLANK_TEMPLATE as Template);

  useEffect(() => {
    const params = getQueryParams({ entity: entity.key });
    fetch(BASE_URL + "template" + params)
      .then((res) => res.json())
      .then((res) => {
        setTemplates(res);

        if (res[0]) {
          setTemplate(res[0]);
        }
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity]);

  const onEntityChange = (entity: Entity): void => {
    dispatch(setEntity(entity));
  };

  const selectTemplate = (id: Template["_id"]): void => {
    const index = templates.findIndex((template) => template._id === id);
    const template = templates[index];

    setTemplate(template);
  };

  const updateTemplateStatus = (id: Template["_id"], active: boolean): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = templates.findIndex((template) => template._id === id);
    const template = templates[index];

    const params = getRequestOptions("POST", { id, active });
    fetch(BASE_URL + "update-template-status", params)
      .then((res) => res.json())
      .then((res) => {
        const message = active
          ? `Successfully update ${template.label} to active!`
          : `Successfully update ${template.label} to inactive!`;
        toast(message, { type: "success" });

        const newTemplates = [...templates];
        newTemplates[index] = {
          ...template,
          active,
        };
        setTemplates(newTemplates);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const deleteTemplate = (id: Template["_id"]): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const index = templates.findIndex(
      (template: Template) => template._id === id
    );
    const template = templates[index];
    const newTemplates = [...templates];
    const params = getRequestOptions("DELETE", { id });
    fetch(BASE_URL + "template", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`template ${template.label} deleted successfully!`, {
          type: "success",
        });

        newTemplates.splice(index, 1);
        setTemplates(newTemplates);
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  const addTemplate = (
    label: Template["label"],
    callback: () => void
  ): void => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const template: Template = {
      _id: "",
      entity: entity.key,
      label,
      active: true,
    };
    const params = getRequestOptions("POST", template);
    fetch(BASE_URL + "template", params)
      .then((res) => res.json())
      .then((res) => {
        toast(`Successfully added template ${template.label}`, {
          type: "success",
        });

        const newTemplates = [...templates, { ...template, _id: res._id }];
        setTemplates(newTemplates);
        callback();
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  return (
    <div className="templates-component">
      <Heading content="Templates" />
      <div className="_page-flex _page-height">
        <div className="_fixed _margin-right">
          <EntityFilter
            entityKey={entity.key}
            onChange={(entity: Entity) => onEntityChange(entity)}
          />

          <TemplatesList
            templates={templates}
            template={template}
            selectTemplate={(id: Template["_id"]) => selectTemplate(id)}
            updateTemplateStatus={(id: Template["_id"], active: boolean) =>
              updateTemplateStatus(id, active)
            }
            deleteTemplate={(id: Template["_id"]) => deleteTemplate(id)}
          />

          <AddTemplate
            addTemplate={(label: Template["label"], callback: () => void) =>
              addTemplate(label, callback)
            }
          />
          <Legend slim={true} />
        </div>
        <div className="_grow">
          <TemplateExpenses template={template} entity={entity.key} />
        </div>
      </div>
    </div>
  );
}

export default Templates;
