import React from "react";
import "./checkbox.scss";
import checkboxChecked from "../../assets/svg/checkbox-checked.svg";
import checkbox from "../../assets/svg/checkbox.svg";

function Checkbox(props: Props) {
  let buttonClass = "_clearbutton";
  if (props.disabled) {
    buttonClass += " -disabled";
  }

  return (
    <button
      name={props.name ? props.name : ""}
      className={buttonClass}
      onClick={() => (props.disabled ? "" : props.onClick?.())}
      autoFocus={props.autoFocus}
    >
      {props.checked ? (
        <img
          className="checkbox"
          src={checkboxChecked}
          alt="checkbox checked"
        />
      ) : (
        <img className="checkbox" src={checkbox} alt="checkbox unchecked" />
      )}
    </button>
  );
}

export default Checkbox;

interface Props {
  checked: boolean;
  onClick?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
  name?: string;
}
