import { Earning } from "../models/earnings";
import { Expense, SavedExpense } from "../models/expenses";
import { Installment } from "../models/installments";
import { MonthData } from "../models/months";
import { Template } from "../models/templates";

export const BLANK_EXPENSE: Expense = {
  _id: "",
  sheet: "",
  isPaid: false,
  toWithdraw: false,
  amount: 0,
  expenseName: "",
  type: "savings",
};

export const BLANK_EARNING: Earning = {
  _id: "",
  sheet: "",
  amount: 0,
  from: "",
};

export const BLANK_SAVED_EXPENSE: SavedExpense = {
  _id: "",
  entity: "",
  amount: 0,
  expenseName: "",
  type: "others",
  toWithdraw: false,
};

export const BLANK_TEMPLATE: Template = {
  _id: "",
  entity: "",
  label: "",
  active: false,
};

export const BLANK_INSTALLMENT: Installment = {
  _id: "",
  entity: "",
  amount: 0,
  total: 0,
  expenseName: "",
  startMonth: "",
  startYear: 0,
  endMonth: "",
  endYear: 0,
};

export const BLANK_MONTH_DATA: MonthData = {
  year: 0,
  month: "",
  label: "",
};
