import "./device-limit.scss";

function DeviceLimit() {
  return (
    <div className="device-limit">
      <p>Your Viewport is too small. ☹️</p>
    </div>
  );
}

export default DeviceLimit;
