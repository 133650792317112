import React from "react";
import ReactDOM from "react-dom";

import "react-toggle/style.css";
import "react-toastify/dist/ReactToastify.css";
import "rc-tooltip/assets/bootstrap_white.css";
import "react-contexify/dist/ReactContexify.css";

import "./styles/properties.scss";
import "./styles/styles.scss";
import "./styles/helpers.scss";

import Main from "./components/main/main";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import sheetReducer from "./slices/sheet";

const store = createStore(sheetReducer);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Main />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
