import React from "react";
import { ExpenseComponentType } from "../../models/expenses";
import ExpenseHeader from "./expense-header";
import "./expenses.scss";

function ExpensesWrapper(props: Props) {
  return (
    <div className="expenses-wrapper _table">
      <ExpenseHeader type={props.type} total={props.total} />
      <div className="expense-body">{props.children}</div>

      <div className="_row -empty"></div>
      <div className="_row -footer"></div>
    </div>
  );
}

interface Props {
  type: ExpenseComponentType;
  children?: React.ReactNode;
  total?: number;
}

export default ExpensesWrapper;
