import { Installment } from "../../../models/installments";
import * as dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  getMonthFromIndex,
  getMonthIndex,
  getMonthLabel,
  getMonthsProgress,
} from "../../../utils/dates";
import ButtonSvg from "../../../components-generic/button-svg/button-svg";

function InstallmentItem(props: Props) {
  const [totalMonths, setTotalMonths] = useState(0);
  const [progressMonth, setProgressMonth] = useState(0);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const month = dayjs.default().get("month");
    const year = dayjs.default().get("year");
    const currentDate = dayjs.default(`${year}-${month}-01`);

    const endMonth = getMonthIndex(props.installment.endMonth);
    const endDate = dayjs.default(
      `${props.installment.endYear}-${endMonth}-01`
    );

    const { start, end } = getMonthsProgress(
      props.installment.startMonth,
      props.installment.startYear,
      props.installment.endMonth,
      props.installment.endYear,
      getMonthFromIndex(month),
      year
    );
    setTotalMonths(end);
    setProgressMonth(start);

    if (endDate.diff(currentDate, "month") < 0) {
      setIsDone(true);
    }
  }, [
    props.installment.startMonth,
    props.installment.startYear,
    props.installment.endMonth,
    props.installment.endYear,
  ]);

  return (
    <div className={"installment-item _row " + (isDone && "-done")}>
      <div className="_cell -amount">₱{props.installment.total}</div>
      <div className="_cell -amount">₱{props.installment.amount}</div>
      <div className="_cell -expense">{props.installment.expenseName}</div>
      <div className="_cell -dates">
        {getMonthLabel(props.installment.startMonth)}{" "}
        {props.installment.startYear} -{" "}
        {getMonthLabel(props.installment.endMonth)} {props.installment.endYear}
      </div>
      <div className="_cell -date">{totalMonths} months</div>
      <div className="_cell -progress">
        {progressMonth}/{totalMonths}
      </div>
      <div className="_cell -buttons">
        <ButtonSvg
          svg="delete"
          tip="Delete Sheet"
          label="Delete Sheet"
          onClick={() => props.deleteInstallment(props.installment._id)}
        />
      </div>
    </div>
  );
}

interface Props {
  installment: Installment;
  deleteInstallment: (id: Installment["_id"]) => void;
}

export default InstallmentItem;
