import React from "react";
import { useSelector } from "react-redux";
import SubHeading from "../../../components-generic/sub-heading/sub-heading";
import { getEarnings, getExpenses } from "../../../slices/sheet";
import {
  getPercentage,
  getRemaining,
  getTotal,
  getTotalEarnings,
  getTotalType,
  getTotalWithdraw,
} from "../../../utils/summary";
import "./summary.scss";

function Summary() {
  const expenses = useSelector(getExpenses);
  const earnings = useSelector(getEarnings);

  if (!earnings[0] || !expenses[0]) return <div></div>;

  return (
    <div className="summary-component">
      <SubHeading content="Expense Summary"></SubHeading>
      <div className="summary-container">
        <div className="summary">
          <p className="label">Total Income: </p>
          <p className="amount">₱ {getTotalEarnings(earnings)}</p>
        </div>
        <hr></hr>
        <div className="summary">
          <p className="label">Total Savings: </p>
          <p className="percentage">
            ({getPercentage(earnings, getTotalType(expenses, "savings"))}%)
          </p>
          <p className="amount">₱ {getTotalType(expenses, "savings")}</p>
        </div>
        <div className="summary">
          <p className="label">Total Expenses: </p>
          <p className="percentage">
            ({getPercentage(earnings, getTotalType(expenses, "expenses"))}%)
          </p>
          <p className="amount">₱ {getTotalType(expenses, "expenses")}</p>
        </div>
        <div className="summary">
          <p className="label">Total Subscriptions: </p>
          <p className="percentage">
            ({getPercentage(earnings, getTotalType(expenses, "subscriptions"))}
            %)
          </p>
          <p className="amount">₱ {getTotalType(expenses, "subscriptions")}</p>
        </div>
        <div className="summary">
          <p className="label">Total Installment: </p>
          <p className="percentage">
            ({getPercentage(earnings, getTotalType(expenses, "installment"))}%)
          </p>
          <p className="amount">₱ {getTotalType(expenses, "installment")}</p>
        </div>
        <div className="summary">
          <p className="label">Total Others: </p>
          <p className="percentage">
            ({getPercentage(earnings, getTotalType(expenses, "others"))}%)
          </p>
          <p className="amount">₱ {getTotalType(expenses, "others")}</p>
        </div>
        <hr></hr>
        <div className="summary">
          <p className="label">Total: </p>
          <p className="percentage">
            ({getPercentage(earnings, getTotal(expenses))}%)
          </p>
          <p className="amount -highlight">₱ {getTotal(expenses)}</p>
        </div>
        <div className="summary">
          <p className="label">Total Remaining: </p>
          <p className="amount">₱ {getRemaining(expenses, earnings)}</p>
        </div>
        <hr></hr>
        <div className="summary">
          <p className="label">To Withdraw: </p>
          <p className="amount">₱ {getTotalWithdraw(expenses)}</p>
        </div>
      </div>
    </div>
  );
}

export default Summary;
