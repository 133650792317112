import { entities } from "../data/entities";
import { Entity } from "../models/entities";

export function getEntityLabel(key: Entity["key"]): string {
  const entityIndex = entities.findIndex((entity) => entity.key === key);
  const entity = entities[entityIndex];

  if (entity) {
    return entity.label;
  }

  return "";
}
