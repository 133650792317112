import { useState } from "react";
import Button from "../../../components-generic/button/button";
import { DebounceInput } from "react-debounce-input";
import { Sheet } from "../../../models/sheets";
import * as dayjs from "dayjs";
import { months } from "../../../data/months";
import { Month } from "../../../models/months";

function AddSheetForm(props: Props) {
  const startYear = 2021;
  const currentYear = dayjs.default().get("year");
  const diff = currentYear - startYear;

  const [label, setLabel] = useState("");
  const [errorLabel, setErrorLabel] = useState(false);

  const { setMonth, setYear } = props;

  const addSheet = (): void => {
    if (label === "") {
      setErrorLabel(true);
      return;
    }

    props.addSheet(label, props.year, props.month, resetForm);
  };

  const resetForm = (): void => {
    setErrorLabel(false);
    setLabel("");
  };

  return (
    <div className="add-sheet _margin-top _margin-bottom">
      <form
        className="_margin-bottom"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {errorLabel ? <p className="_error-message">Label is required</p> : ""}
        <DebounceInput
          className={"_input " + (errorLabel ? "-error" : "")}
          debounceTimeout={300}
          onChange={(event) => {
            setLabel(event.target.value);
            setErrorLabel(false);
          }}
          value={label}
          placeholder="Sheet label..."
        />

        <select
          className="_input _margin-top"
          onChange={(val) => setMonth(val.target.value)}
          value={props.month}
        >
          {months.map((mo: Month) => (
            <option key={mo.key} value={mo.key}>
              {" "}
              {mo.label}{" "}
            </option>
          ))}
        </select>

        <select
          className="_input _margin-top"
          onChange={(val) => setYear(parseInt(val.target.value, 0))}
          value={props.year}
        >
          {[...Array(diff + 2)].map((current, i) => (
            <option key={i}> {i + startYear} </option>
          ))}
        </select>
      </form>
      <Button content="Add Sheet" block={true} onClick={() => addSheet()} />
    </div>
  );
}

export default AddSheetForm;

interface Props {
  month: Sheet["month"];
  year: Sheet["year"];
  setMonth: (month: Sheet["month"]) => void;
  setYear: (year: Sheet["year"]) => void;
  addSheet: (
    label: Sheet["label"],
    year: Sheet["year"],
    month: Sheet["month"],
    callback: () => void
  ) => void;
}
