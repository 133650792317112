import { Component } from "react";

import "./nav.scss";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";
import { months } from "../../data/months";
import { entities } from "../../data/entities";
import ThemeContext from "../../theme-context";
import Toggle from "react-toggle";
import Tooltip from "rc-tooltip";

class Nav extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  toggleTheme(): void {
    this.context.updateTheme();
  }

  render() {
    const monthIndex = dayjs.default().get("month");
    const currentMonth = months[monthIndex].key;
    const currentYear = dayjs.default().get("year");

    const entitiesNavMap: NavItem[] = entities.map((entity) => ({
      link: `/sheet/${entity.key}/${currentMonth}/${currentYear}`,
      asset: entity.icon,
      alt: `${entity.label}`,
    }));

    const navList: NavItem[] = [
      {
        link: "/",
        asset: `/assets/png/calendar.png`,
        alt: "calendar",
      },
      ...entitiesNavMap,
      {
        link: "/sheet/add",
        asset: "/assets/png/sheet.png",
        alt: "add sheets",
      },
      {
        link: "/templates",
        asset: "/assets/png/templates.png",
        alt: "templates",
      },
      {
        link: "/expenses",
        asset: "/assets/png/expenses.png",
        alt: "expenses",
      },
      {
        link: "/installments",
        asset: "/assets/png/installments.png",
        alt: "installments",
      },
    ];

    return (
      <nav className="nav-component">
        {navList.map((item, index) => {
          return (
            <Tooltip
              placement="right"
              trigger={["hover"]}
              overlay={<span>{"navigate to " + item.alt}</span>}
              mouseEnterDelay={0.3}
              key={index}
            >
              <Link className="item" to={item.link}>
                <img src={item.asset} alt={item.alt} />
              </Link>
            </Tooltip>
          );
        })}

        <div className="item -bottom">
          <Tooltip
            placement="right"
            trigger={["hover", "focus"]}
            overlay={<span>toggle theme</span>}
            mouseEnterDelay={1}
          >
            <div>
              <Toggle
                defaultChecked={this.context.theme === "light"}
                onChange={this.toggleTheme.bind(this)}
                icons={false}
              />
            </div>
          </Tooltip>
        </div>
      </nav>
    );
  }
}

interface Props {}
interface State {}

export interface NavItem {
  link: string;
  asset: string;
  alt: string;
}

export default Nav;
Nav.contextType = ThemeContext;
