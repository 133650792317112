import { Entity } from "../../models/entities";
import { Month } from "../../models/months";
import { useDispatch } from "react-redux";
import {
  setInstallments,
  setSavedExpenses,
  setSheetKey,
} from "../../slices/sheet";
import { useContext, useEffect, useState } from "react";
import { getQueryParams, getRequestOptions } from "../../utils/requests";
import { BASE_URL } from "../../data/constants";
import { Sheet } from "../../models/sheets";
import { Link, useNavigate } from "react-router-dom";

import "./sheet.scss";

import Legend from "../../components-generic/legend/legend";
import Expenses from "../expenses/expenses";
import Button from "../../components-generic/button/button";
import SheetsHeading from "../../components-generic/sheets-heading/sheets-heading";
import Earnings from "./earnings/earnings";
import Summary from "./summary/summary";
import { ExpenseComponentType } from "../../models/expenses";
import { toast } from "react-toastify";
import ButtonSvg from "../../components-generic/button-svg/button-svg";
import DateButtons from "../../components-generic/date-buttons/date-buttons";
import {
  handleDemoError,
  handleEndpointError,
} from "../../utils/handle-errors";
import ThemeContext from "../../theme-context";

function Sheets(props: Props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const theme = useContext(ThemeContext);

  const { entity, month, year, index } = props;
  const [sheets, setSheets] = useState([] as Sheet[]);

  useEffect(() => {
    dispatch(setSheetKey({ entity, month, year }));
  }, [index, dispatch, entity, month, year]);

  useEffect(() => {
    const params = getQueryParams({ entity: entity });
    fetch(BASE_URL + "saved-expense" + params)
      .then((res) => res.json())
      .then((res) => {
        dispatch(setSavedExpenses(res));
      })
      .catch((error) => {
        handleEndpointError();
      });

    fetch(BASE_URL + "installment" + params)
      .then((res) => res.json())
      .then((res) => {
        dispatch(setInstallments(res));
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity, dispatch]);

  const deleteSheet = () => {
    if (theme.demo) {
      handleDemoError();
      return;
    }
    const id = sheets[props.index]._id;
    const params = getRequestOptions("DELETE", { id });
    fetch(BASE_URL + "sheet", params)
      .then((res) => res.json())
      .then((res) => {
        toast(
          `Successfully deleted sheet ${
            sheets[props.index].label
          } successfully!`,
          {
            type: "success",
          }
        );

        navigate(`../sheet/${entity}/${month}/${year}`, { replace: true });
      })
      .catch((error) => {
        handleEndpointError();
      });
  };

  useEffect(() => {
    const params = getQueryParams({ entity, month, year });
    fetch(BASE_URL + "sheet" + params)
      .then((res) => res.json())
      .then((res) => {
        setSheets(res);
      })
      .catch((error) => {
        handleEndpointError();
      });
  }, [entity, month, year]);

  if (!sheets || !sheets[0]) return <div></div>;

  return (
    <div className="sheet-component">
      <div className="headings">
        <SheetsHeading sheet={sheets ? sheets[props.index].label : ""} />
        <ButtonSvg
          svg="delete"
          tip="Delete Sheet"
          label="Delete Sheet"
          onClick={() => deleteSheet()}
        />
      </div>
      <div className="sheet-flex _flex">
        <div className="left">
          {sheets && sheets[props.index] ? (
            <div>
              <Earnings sheet={sheets[props.index]._id} key={props.index} />
              <Summary />
            </div>
          ) : (
            ""
          )}
          <div className="_top-auto">
            <Button block={true} slim={true}>
              <Link to={`/sheet/${entity}/${month}/${year}`}>Go Back</Link>
            </Button>
          </div>
        </div>
        <div className="right">
          <Legend />
          {sheets && sheets[props.index] ? (
            <Expenses
              sheet={sheets[props.index]}
              key={props.index}
              type={ExpenseComponentType.Sheet}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="sheet-footer">
        <div className="_button-group">
          {sheets
            ? sheets.map((sheet: Sheet, ind: number) => {
                let className = "_clearbutton ";
                if (ind === parseInt(`${props.index}`)) {
                  className += "-active";
                }
                return (
                  <button
                    className={className}
                    key={ind}
                    disabled={ind === parseInt(`${props.index}`)}
                  >
                    <Link to={`/sheet/${entity}/${month}/${year}/${ind}`}>
                      {sheet.label}
                    </Link>
                  </button>
                );
              })
            : ""}
        </div>
        <div className="_left-auto">
          <DateButtons
            entity={props.entity}
            month={props.month}
            year={props.year}
          />
        </div>
      </div>
    </div>
  );
}

interface Props {
  entity: Entity["key"];
  month: Month["key"];
  year: number;
  index: number;
}

export default Sheets;
