import React from "react";
import { ExpenseComponentType } from "../../models/expenses";

function ExpenseHeader(props: Props) {
  return (
    <div className="expenses-header _row -header">
      <div className="_cell -type"></div>
      {props.type === ExpenseComponentType.Sheet && (
        <div className="_cell -checkbox">Status</div>
      )}

      {props.type === ExpenseComponentType.AddSheetTemplates && (
        <div className="_cell -checkbox">Include</div>
      )}

      <div className="_cell -checkbox">Withdraw</div>

      <div className="_cell -amount">Amount</div>
      <div className="_cell -expense">Expense Name</div>

      {props.total ? (
        <div className="_cell -total">
          total: <span className="value">₱{props.total}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

interface Props {
  type: ExpenseComponentType;
  total?: number;
}

export default ExpenseHeader;
