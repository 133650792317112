import "./browser-limit.scss";
import * as Bowser from "bowser";
import { useEffect, useState } from "react";

function BrowserLimit() {
  const [isInvalidBrowser, setIsInvalidBrowser] = useState(false);

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isInvalid = browser.satisfies({
      windows: {
        "internet explorer": "<=11",
      },
      firefox: "<52",
      chrome: "<57",
      safari: "<10.1",
      edge: "<16",
      opera: "<44",
      samsung_internet: "<6.2",
    });

    if (typeof isInvalid === "boolean") {
      setIsInvalidBrowser(isInvalid);
    }
  }, []);

  if (isInvalidBrowser === false) return <span></span>;

  return (
    <div className="browser-limit">
      <p className="title">Browser Not Supported! ☹️</p>
      <p>This web application is best viewed in Google Chrome.</p>
      <p>
        {" "}
        Please use another browser or upgrade your current one to a later
        version.
      </p>
    </div>
  );
}

export default BrowserLimit;
