import { Entity } from "../models/entities";

export const entities: Entity[] = [
  {
    label: "Kwe",
    key: "kwe",
    icon: "/assets/png/kwe.png",
  },
  {
    label: "AJ",
    key: "aj",
    icon: "/assets/png/aj.png",
  },
];
